import Vue from "vue";
import i18n from "@/i18n";
import VueRouter from "vue-router";
import ModuleList from "@/views/module/ModuleList.vue";
import ModuleVersions from "@/views/module/ModuleVersions.vue";
import AppHome from "@/views/AppHome.vue";
import RunList from "@/views/run/RunList.vue";
import SimulationRun from "@/views/run/SimulationRun.vue";
import CreateRun from "@/views/run/CreateRun.vue";
import CopyRun from "@/views/run/CopyRun.vue";
import ProcessTaskList from "@/views/businessProcess/processTask/ProcessTaskList.vue";
import CreateProcessTask from "@/views/businessProcess/processTask/CreateProcessTask.vue";
import ProcessTask from "@/views/businessProcess/processTask/ProcessTask.vue";
import BPDefinitionList from "@/views/businessProcess/bpDefinition/BPDefinitionList.vue";
import BPDefinition from "@/views/businessProcess/bpDefinition/BPDefinition.vue";
import CustomizeBPDefinitionImage from "@/views/businessProcess/bpDefinition/CustomizeBPDefinitionImage.vue";
import CreateBPDefinition from "@/views/businessProcess/bpDefinition/CreateBPDefinition.vue";
import BPInstanceList from "@/views/businessProcess/BPInstanceList.vue";
import CreateBPInstance from "@/views/businessProcess/CreateBPInstance.vue";
import BPInstance from "@/views/businessProcess/BPInstance.vue";
import UserList from "@/views/security/UserList.vue";
import SecUser from "@/views/security/SecUser.vue";
import CreateUser from "@/views/security/CreateUser.vue";
import EditUser from "@/views/security/EditUser.vue";
import VaultList from "@/views/security/VaultList.vue";
import CreateVault from "@/views/security/CreateVault.vue";
import SecVault from "@/views/security/SecVault.vue";
import RoleList from "@/views/security/RoleList.vue";
import CreateRole from "@/views/security/CreateRole.vue";
import EditRole from "@/views/security/EditRole.vue";
import SecRole from "@/views/security/SecRole.vue";
import FileEnvList from "@/views/fileEnv/FileEnvList.vue";
import FileEnv from "@/views/fileEnv/FileEnv.vue";
import FileVersions from "@/views/file/FileVersions.vue";
import CreateFileEnv from "@/views/fileEnv/CreateFileEnv.vue";
import EditFileEnv from "@/views/fileEnv/EditFileEnv.vue";
import PropertyList from "@/views/PropertyList.vue";
import FileList from "@/views/file/FileList.vue";
import LogList from "@/views/LogList.vue";
import SystemInfo from "@/views/SystemInfo.vue";

import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: AppHome,
  },
  {
    path: "/module/list",
    name: "Modules",
    component: ModuleList,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.modules.modules"),
      ]),
    },
  },
  {
    path: "/module/view/:moduleName",
    name: "ModuleVersions",
    component: ModuleVersions,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.modules.releases"),
      ]),
    },
  },
  {
    path: "/simulation-run/list",
    name: "Runs",
    component: RunList,
    beforeEnter: redirectIfNotRunReadPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [i18n.t("global.run.runs")]),
    },
  },
  {
    path: "/simulation-run/view/:runId",
    name: "Run",
    component: SimulationRun,
    beforeEnter: redirectIfNotRunReadPermission,
    meta: {
      title: (route) => `${i18n.t("global.run.run")} #${route.params.runId}`,
    },
  },
  {
    path: "/simulation-run/create",
    name: "Create Run",
    component: CreateRun,
    beforeEnter: redirectIfNotRunCreatePermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.run.run").toLowerCase(),
      ]),
    },
  },
  {
    path: "/simulation-run/copy/:runId",
    name: "Copy Run",
    component: CopyRun,
    beforeEnter: redirectIfNotRunCreatePermission,
    meta: {
      title: i18n.t("global.header.copy", [
        i18n.t("global.run.run").toLowerCase(),
      ]),
    },
  },
  {
    path: "/process-task/list",
    name: "Process tasks",
    component: ProcessTaskList,
    beforeEnter: redirectIfNotProcessTaskReadPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.business-process.process-tasks"),
      ]),
    },
  },
  {
    path: "/process-task/create",
    name: "Create process task",
    component: CreateProcessTask,
    beforeEnter: redirectIfNotProcessTaskCreatePermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.business-process.process-task").toLowerCase(),
      ]),
    },
  },
  {
    path: "/process-task/view/:taskId",
    name: "Process task",
    component: ProcessTask,
    beforeEnter: redirectIfNotProcessTaskReadPermission,
    meta: { title: i18n.t("global.business-process.process-task") },
  },
  {
    path: "/bp-definition/list",
    name: "BP definitions",
    component: BPDefinitionList,
    beforeEnter: redirectIfNotBpDefReadPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.business-process.bp-definitions").toLowerCase(),
      ]),
    },
  },
  {
    path: "/bp-definition/view/:bpId",
    name: "BP definition",
    component: BPDefinition,
    beforeEnter: redirectIfNotBpDefReadPermission,
    meta: { title: i18n.t("global.business-process.bp-definition") },
  },
  {
    path: "/bp-definition/edit-image/:bpVersionId",
    name: "Edit BP definition image",
    component: CustomizeBPDefinitionImage,
    beforeEnter: redirectIfNotBpDefModifyPermission,
    meta: { title: i18n.t("global.business-process.customize-image") },
  },
  {
    path: "/bp-definition/create",
    name: "Create BP definition",
    component: CreateBPDefinition,
    beforeEnter: redirectIfNotBpDefCreatePermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.business-process.bp-definition").toLowerCase(),
      ]),
    },
  },
  {
    path: "/bp-instance/list",
    name: "BP instances",
    component: BPInstanceList,
    beforeEnter: redirectIfNotBpInstReadPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.business-process.bp-instances").toLowerCase(),
      ]),
    },
  },
  {
    path: "/bp-instance/create",
    name: "Create BP instance",
    component: CreateBPInstance,
    beforeEnter: redirectIfNotBpInstCreatePermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.business-process.bp-instance").toLowerCase(),
      ]),
    },
  },
  {
    path: "/bp-instance/view/:bpId",
    name: "BP instance",
    component: BPInstance,
    beforeEnter: redirectIfNotBpInstReadPermission,
    meta: {
      title: (route) =>
        `${i18n.t("global.business-process.bp-instance")} #${
          route.params.bpId
        }`,
    },
  },
  {
    path: "/user/list",
    name: "Users",
    component: UserList,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.menu.administration.users").toLowerCase(),
      ]),
    },
  },
  {
    path: "/user/view/:userId",
    name: "User",
    component: SecUser,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: { title: i18n.t("global.concepts.sysuser") },
  },
  {
    path: "/user/create",
    name: "Create user",
    component: CreateUser,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.concepts.sysuser").toLowerCase(),
      ]),
    },
  },
  {
    path: "/user/view/:userId/edit",
    name: "Edit user",
    component: EditUser,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.header.edit", [
        i18n.t("global.concepts.sysuser").toLowerCase(),
      ]),
    },
  },
  {
    path: "/role/list",
    name: "Roles",
    component: RoleList,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.menu.administration.roles").toLowerCase(),
      ]),
    },
  },
  {
    path: "/role/create",
    name: "Create role",
    component: CreateRole,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.concepts.sysrole").toLowerCase(),
      ]),
    },
  },
  {
    path: "/role/view/:roleId",
    name: "Role",
    component: SecRole,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: { title: i18n.t("global.concepts.sysrole") },
  },
  {
    path: "/role/view/:roleId/edit",
    name: "Edit role",
    component: EditRole,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.header.edit", [
        i18n.t("global.concepts.sysrole").toLowerCase(),
      ]),
    },
  },
  {
    path: "/vault/list",
    name: "Vaults",
    component: VaultList,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.menu.administration.vaults").toLowerCase(),
      ]),
    },
  },
  {
    path: "/vault/view/:vaultId",
    name: "Vault",
    component: SecVault,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: { title: i18n.t("global.concepts.sysvault") },
  },
  {
    path: "/vault/create",
    name: "Create vault",
    component: CreateVault,
    beforeEnter: redirectIfNotSecurityPermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.concepts.sysvault").toLowerCase(),
      ]),
    },
  },
  {
    path: "/file/view/:fileId",
    name: "File",
    component: FileVersions,
    meta: { title: i18n.t("global.concepts.file") },
  },
  {
    path: "/file-env/list",
    name: "File envs",
    component: FileEnvList,
    beforeEnter: redirectIfNotFileEnvReadPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.menu.file-environments").toLowerCase(),
      ]),
    },
  },
  {
    path: "/file-env/view/:fileEnvId",
    name: "File env",
    component: FileEnv,
    beforeEnter: redirectIfNotFileEnvReadPermission,
    meta: { title: i18n.t("global.concepts.file-environment") },
  },
  {
    path: "/file-env/create",
    name: "Create file env",
    component: CreateFileEnv,
    beforeEnter: redirectIfNotFileEnvCreatePermission,
    meta: {
      title: i18n.t("global.header.create", [
        i18n.t("global.concepts.file-environment").toLowerCase(),
      ]),
    },
  },
  {
    path: "/file-env/view/:fileEnvId/edit",
    name: "Edit file env",
    component: EditFileEnv,
    beforeEnter: redirectIfNotFileEnvModifyPermission,
    meta: {
      title: i18n.t("global.header.edit", [
        i18n.t("global.concepts.file-environment").toLowerCase(),
      ]),
    },
  },

  {
    path: "/property/list",
    name: "Properties",
    component: PropertyList,
    beforeEnter: redirectIfNotEnvPropsPermission,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.menu.environment-properties").toLowerCase(),
      ]),
    },
  },

  {
    path: "/file/list",
    name: "Files",
    component: FileList,
    meta: {
      title: i18n.t("global.concepts.list-of", [
        i18n.t("global.menu.files").toLowerCase(),
      ]),
    },
  },

  {
    path: "/log",
    name: "Log",
    component: LogList,
    beforeEnter: redirectIfNotLogPermission,
    meta: {
      title: i18n.t("global.concepts.log"),
    },
  },

  {
    path: "/info",
    name: "Info",
    component: SystemInfo,
    beforeEnter: redirectIfNotInfoPermission,
    meta: {
      title: i18n.t("global.info.info"),
    },
  },
];

function redirectIfNotPermitted(to, from, next, permission) {
  if (!permission) next({ name: "Home" });
  else next();
}

function redirectIfNotRunReadPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.runReadPermission);
}

function redirectIfNotRunCreatePermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.runCreatePermission);
}

function redirectIfNotBpInstReadPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.bpInstReadPermission);
}

function redirectIfNotBpDefReadPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.bpDefReadPermission);
}

function redirectIfNotProcessTaskReadPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.processTaskReadPermission);
}

function redirectIfNotProcessTaskCreatePermission(to, from, next) {
  redirectIfNotPermitted(
    to,
    from,
    next,
    store.state.processTaskCreatePermission
  );
}

function redirectIfNotBpDefCreatePermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.bpDefCreatePermission);
}

function redirectIfNotBpDefModifyPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.bpDefModifyPermission);
}

function redirectIfNotBpInstCreatePermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.bpInstCreatePermission);
}

function redirectIfNotSecurityPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.securityPermission);
}

function redirectIfNotFileEnvReadPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.fileEnvReadPermission);
}

function redirectIfNotFileEnvCreatePermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.fileEnvCreatePermission);
}

function redirectIfNotFileEnvModifyPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.fileEnvModifyPermission);
}

function redirectIfNotEnvPropsPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.envPropsPermission);
}

function redirectIfNotLogPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.logPermission);
}

function redirectIfNotInfoPermission(to, from, next) {
  redirectIfNotPermitted(to, from, next, store.state.infoPermission);
}

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const { title } = to.meta;
  document.title =
    (typeof title === "function" ? title(to) : title) || "Compliance server";
  next();
});

export default router;
