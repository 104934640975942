<template>
  <module-template
    id="scrolltainer"
    title
    canDelete
    canEdit
    canList
    @editItem="editRole"
    @list="listRoles"
    @deleteItem="deleteRole"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.concepts.sysrole") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ role.name }}</div>
      </v-row>
    </template>
    <v-card>
      <v-card-text>
        <h6 class="py-4">
          {{ $t("global.administration.permissions") }}
        </h6>
        <v-expansion-panels multiple v-model="panel" accordion>
          <v-expansion-panel
            v-for="catPermission in categoryPermissions"
            :key="catPermission.category"
          >
            <v-expansion-panel-header color="#f2f2f2" class="font-weight-bold">
              {{ catPermission.category }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense justify="start">
                <v-col
                  v-for="perm in catPermission.permissions"
                  :key="perm.name"
                  cols="2"
                >
                  <v-checkbox
                    hide-details
                    dense
                    disabled
                    :label="perm.translated"
                    :value="perm.name"
                    v-model="selectedPermissions"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { get, remove } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";

export default {
  name: "SecRole",

  components: {
    ModuleTemplate,
  },

  data() {
    return {
      role: {},
      selectedPermissions: [],
      categoryPermissions: [],
      panel: [],
    };
  },

  methods: {
    async getRole(id) {
      return await get("/role/" + id).catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    async deleteRole() {
      await remove("/role/" + this.role.id)
        .then(() => {
          this.$router.push({ name: "Roles" });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    editRole() {
      this.$router.push({
        name: "Edit role",
        params: { roleId: this.role.id },
      });
    },

    async getPermissionsForRole(roleId) {
      return await get("/role/" + roleId + "/permissions").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    listRoles() {
      this.$router.push({ name: "Roles" });
    },

    async getCategoryPermissions() {
      return await get("/permission").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
  },
  async created() {
    this.categoryPermissions = await this.getCategoryPermissions();
    this.panel = [...Array(this.categoryPermissions.length).keys()];

    const roleId = this.$route.params.roleId;

    this.selectedPermissions = await this.getPermissionsForRole(roleId);

    this.role = await this.getRole(roleId);
  },
};
</script>
