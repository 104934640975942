<template>
  <div class="mt-3">
    <v-row v-for="formRow in formRows" :key="formRow.key">
      <v-col cols="6">
        <v-autocomplete
          v-if="formRow.propertyEditorType === 'ENUMERATION'"
          :label="label(formRow)"
          :items="formRow.options"
          :rules="formRow.required ? [notEmptyRule] : []"
          v-model="formValues[formRow.key]"
          outlined
          persistent-placeholder
          @input="onInput()"
        />
        <v-text-field
          v-else
          :label="label(formRow)"
          :rules="formRow.required ? [notEmptyRule, noTagsRule] : [noTagsRule]"
          v-model="formValues[formRow.key]"
          outlined
          persistent-placeholder
          @input="onInput()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "PropertyForm",
  props: {
    formRows: [],
  },
  data() {
    return {
      formValues: {},
      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
    };
  },
  watch: {
    formRows: function (val) {
      this.setDefaultValues(val);
    },
  },
  methods: {
    label(formRow) {
      return (formRow.alias ?? formRow.key) + (formRow.required ? "*" : "");
    },

    setDefaultValues(formRows) {
      this.formValues = {};

      formRows.forEach((row) => {
        if (row.defaultValue) {
          this.$set(this.formValues, row.key, row.defaultValue);
        }
      });
      this.onInput();
    },

    onInput() {
      const data = {};

      this.formRows.forEach((row) => {
        if (row.defaultValue && !this.formValues[row.key]) {
          data[row.key] = row.defaultValue;
        } else {
          data[row.key] = this.formValues[row.key];
        }
      });
      this.$emit("input", data);
    },
  },
};
</script>
