<template>
  <div>
    <v-card v-if="hasAccess">
      <v-card-text>
        <strong>{{ evalMessage }}</strong></v-card-text
      >
      <v-card-actions v-if="!endDate"
        ><v-btn @click="approveConfirm" color="primary">{{
          $t("global.business-process.approve")
        }}</v-btn
        ><v-btn color="error" @click="rejectConfirm">{{
          $t("global.business-process.reject")
        }}</v-btn></v-card-actions
      >
      <v-card-text v-else>
        <v-row>
          <v-col
            ><strong>{{ $t("global.concepts.status") }}</strong></v-col
          >
          <v-col>
            <v-chip v-if="result" color="success">{{
              $t("global.business-process.approved")
            }}</v-chip>
            <v-chip v-else color="error">{{
              $t("global.business-process.rejected")
            }}</v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><strong>{{
              $t("global.business-process.evaluated-by")
            }}</strong></v-col
          >
          <v-col>{{ flowInteraction.user.username }}</v-col>
        </v-row>
        <v-row>
          <v-col
            ><strong>{{
              $t("global.business-process.evaluation-date")
            }}</strong></v-col
          >
          <v-col>{{ endDate }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-alert v-else type="error">{{
      $t("global.business-process.task-no-access")
    }}</v-alert>
    <v-dialog v-model="showConfirm" width="500">
      <confirm-alert
        @cancel="cancelApproveReject"
        @confirm="confirmApproveReject"
      ></confirm-alert>
    </v-dialog>
  </div>
</template>

<script>
import { get, post } from "@/model/api";
import ConfirmAlert from "@/components/ConfirmAlert";
import { formatDate } from "@/model/util";

export default {
  name: "EvaluationTask",
  components: {
    ConfirmAlert,
  },
  props: { flowTaskId: Number },
  data() {
    return {
      flowInteraction: {},
      message: "",
      showConfirm: false,
      approved: false,
      endDate: undefined,
      result: "",
      hasAccess: true,
    };
  },

  computed: {
    evalMessage() {
      return (
        this.$t("global.business-process.evaluation") + ": " + this.message
      );
    },
  },

  methods: {
    async getFlowInteractionForFlowTask(flowTaskId) {
      return await get("/flow-task/" + flowTaskId + "/flow-interaction").catch(
        (error) => {
          if (error.response.status === 403) {
            this.hasAccess = false;
          } else {
            this.$store.commit("showError", error);
          }
        }
      );
    },
    async getFlowInteraction(interactionId) {
      return await get("/flow-interaction/" + interactionId).catch((error) =>
        this.$store.commit("showError", error)
      );
    },
    async getMessage(interactionId) {
      return await get(
        "/flow-interaction/" + interactionId + "/evaluation-message"
      ).catch((error) => this.$store.commit("showError", error));
    },
    async confirmApproveReject() {
      await this.evaluate(this.flowInteraction.id);
      this.showConfirm = false;
      this.loadData();
    },
    async evaluate(interactionId) {
      const data = { approved: this.approved };
      return await post(
        "/flow-interaction/" + interactionId + "/evaluate",
        data
      ).catch((error) => this.$store.commit("showError", error));
    },
    approveConfirm() {
      this.showConfirm = true;
      this.approved = true;
    },
    rejectConfirm() {
      this.showConfirm = true;
      this.approved = false;
    },
    cancelApproveReject() {
      this.showConfirm = false;
    },
    async loadData() {
      this.flowInteraction = await this.getFlowInteractionForFlowTask(
        this.flowTaskId
      );
      if (this.flowInteraction) {
        this.message = await this.getMessage(this.flowInteraction.id);
        this.endDate = formatDate(this.flowInteraction.endDate);
        this.result = JSON.parse(this.flowInteraction.jsonResult)?.approved;
      }
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>
