<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.business-processes.process-tasks')"
    :canCreate="this.$store.state.processTaskCreatePermission"
    :createToolTip="
      $t('global.header.newFem', [
        $t('global.business-process.task').toLowerCase(),
      ])
    "
    @newItem="create"
  >
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      no-pagination
      @row-clicked="onRowClick"
    />
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { get } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";

export default {
  name: "ProcessTaskList",
  components: {
    OolibaBasicTable,
    ModuleTemplate,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.type"), value: "type" },
      ],
      items: [],
      tasks: [],

      busy: true,
    };
  },
  methods: {
    create() {
      this.$router.push({ name: "Create process task" });
    },

    async getTasks() {
      return await get("/flow-task-config").catch((error) =>
        this.onError(error)
      );
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    onRowClick(record) {
      const taskId = record.id;
      this.$router.push({ name: "Process task", params: { taskId: taskId } });
    },
  },
  async created() {
    this.tasks = await this.getTasks();

    if (this.tasks) {
      this.tasks.forEach((value) => {
        const item = {};

        item["id"] = value.master.id;
        item[this.headers[0].value] = value.name;
        item[this.headers[1].value] = value.type;

        this.items.push(item);
      });
      this.busy = false;
    }
  },
};
</script>
