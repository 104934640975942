<template>
  <div>
    <v-row dense align-content="center" class="mb-2">
      <v-col cols="auto">
        <basic-switch
          cl="mt-0 px-2"
          v-model="showPath"
          dense
          :label="$t('global.run.show-path')"
          hide-details
          reversed
        />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn color="primary" @click="onDownload(downloadZipLink)">{{
          $t("global.run.download-zip")
        }}</v-btn>
      </v-col>
    </v-row>
    <ooliba-basic-table
      :headers="headers"
      :items="itemsFiltered"
      :loading="busy"
      sort-by="name"
      no-pagination
    >
      <template #[`item.env`]="{ item }">
        <v-chip small outlined v-if="item.envIsLocal">LOCAL</v-chip>
        <file-env-chip
          v-if="!item.envIsLocal && item.env"
          :color="item.envColor"
          :label="item.env"
        >
        </file-env-chip>
      </template>

      <template #[`item.name`]="{ item }">
        <router-link
          v-if="item.publishedFileId"
          class="no-underline"
          :to="`/file/view/${item.publishedFileId}`"
          >{{ item.name }}</router-link
        >
      </template>
      <template #[`item.publish`]="{ item }">
        <v-tooltip top v-if="item.showPublish">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-if="item.showPublish" @click="onPublish(item)"
              >pic-save</v-icon
            >
          </template>
          <span>{{ $t("global.msg.save-details") }}</span>
        </v-tooltip>
      </template>
      <template #[`item.download`]="{ item }">
        <v-icon v-if="item.href" @click="onDownload(item.href)"
          >pic-import</v-icon
        >
      </template>
      <template #[`item.size`]="{ item }">
        <span v-if="item.publishedFileId">{{
          `${Math.round(item.size / 1000)} KB`
        }}</span>
      </template>
    </ooliba-basic-table>
  </div>
</template>

<script>
import { get, downloadFile, put } from "@/model/api";

import BasicSwitch from "@/components/BasicSwitch";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import FileEnvChip from "@/components/FileEnvChip";

export default {
  name: "RunOutputFiles",
  components: { OolibaBasicTable, BasicSwitch, FileEnvChip },
  props: {
    run: {},
  },

  data() {
    return {
      busy: true,
      headers: [
        {
          text: this.$t("global.environment.env"),
          value: "env",
          align: "center",
          width: 108,
        },
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.size"), value: "size", width: 100 },
        {
          text: this.$t("global.action.save"),
          value: "publish",
          align: "center",
          width: 50,
          sortable: false,
        },
        {
          text: this.$t("global.action.download"),
          value: "download",
          align: "center",
          width: 50,
          sortable: false,
        },
      ],
      items: [],
      outputFileInfoes: [],

      showPath: false,
    };
  },

  computed: {
    itemsFiltered() {
      let items = [...this.items];
      if (!this.showPath && items && items.length) {
        items = items.map((item) => {
          let itemWithPathRemove = { ...item };
          let filename = itemWithPathRemove.name;

          itemWithPathRemove.name =
            filename && filename.length
              ? `${filename}`.substring(filename.lastIndexOf("/") + 1)
              : filename;
          return itemWithPathRemove;
        });
      }
      return items;
    },

    downloadZipLink() {
      return "simulationRun/" + this.run.infoId + "/download-output-files";
    },
  },

  watch: {
    run: {
      immediate: true,
      deep: true,
      async handler() {
        this.items = [];
        if (this.run && this.run.infoId) {
          this.busy = true;
          this.outputFileInfoes = await this.getOutputFileInfoes();
          this.items = this.getItems();
          this.busy = false;
        }
      },
    },
  },

  methods: {
    getItems() {
      if (!this.outputFileInfoes) return [];
      let items = [];
      this.outputFileInfoes.forEach((value) => {
        const item = {};

        item.id = value.id;
        item.name = value.name;
        item.published = value.published;
        item.publishable = value.publishable;
        item.showPublish =
          !value.published &&
          value.publishable &&
          this.$store.state.runCreatePermission;
        item.publishedFileId = value.publishedFileId;
        item.env = value.fileEnvLabel;
        item.envColor = value.fileEnvColor;
        item.envIsLocal = value.fileEnvIsLocal;
        if (value.fileVersionSize) {
          item.size = value.fileVersionSize;
        }
        item.href = `simulationRun/output-file-info/${value.id}/download`;

        items.push(item);
      });
      return items;
    },

    async getOutputFileInfoes() {
      const path =
        "/simulationRun/run-info/" + this.run.infoId + "/output-file-info";

      return await get(path).catch((err) =>
        this.$store.commit("showError", err)
      );
    },

    onDownload(href) {
      downloadFile(href).catch((err) => {
        this.$store.commit("showError", err);
      });
    },

    async onPublish(item) {
      const row = this.items.findIndex((i) => i.id === item.id);
      return await put(
        "/simulationRun/import-output?runInfoId=" +
          this.run.infoId +
          "&runOutputId=" +
          item.id
      )
        .then((r) => {
          item = this.items[row];
          item.env = r.fileEnvLabel;
          item.envColor = r.fileEnvColor;
          item.envIsLocal = r.fileEnvIsLocal;
          item.showPublish = false;
          item.publishedFileId = r.publishedFileId;
          item.size = r.size;
          this.items = [...this.items]; // this is necessary to trigger an update of itemsFiltered
        })
        .catch((err) => this.$store.commit("showError", err));
    },
  },
};
</script>
