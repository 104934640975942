<template>
  <module-template
    id="scrolltainer"
    title
    :canEdit="$store.state.processTaskModifyPermission"
    canList
    :canDelete="$store.state.processTaskDeletePermission"
    @deleteItem="deleteTask"
    @editItem="onEdit"
    @list="listTasks"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.business-process.task") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ selectedVersion.name }}</div>
      </v-row>
    </template>
    <v-card>
      <v-card-text>
        <ooliba-basic-table
          :headers="headers"
          :items="items"
          :loading="busy"
          no-pagination
          :selected.sync="selectedVersion"
          @row-clicked="onRowClick"
        >
          <template #[`item.createdOn`]="{ item }">
            {{ formatDate(item.createdOn) }}</template
          >
        </ooliba-basic-table>
        <h6 class="py-4">
          {{ details }}
        </h6>
        <v-form @submit.prevent="editTask" class="mx-auto" v-model="valid">
          <process-task-form-fields
            v-if="initFormData"
            :disabled="!showEdit"
            :isEdit="showEdit"
            :init-values="initFormData"
            @input="onInput($event)"
          ></process-task-form-fields>
          <form-buttons
            :can-submit="valid"
            v-if="showEdit"
            :confirmText="$t('global.action.save')"
            @cancel="cancelEdit"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import ProcessTaskFormFields from "@/components/businessProcess/ProcessTaskFormFields";
import { get, remove, put } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import FormButtons from "@/components/FormButtons";
import { formatDate } from "@/model/util";

export default {
  name: "ProcessTask",
  components: {
    ModuleTemplate,
    OolibaBasicTable,
    ProcessTaskFormFields,
    FormButtons,
  },

  data() {
    return {
      currentVersion: {},
      selectedVersion: {},
      initFormData: undefined,
      formData: undefined,
      taskVersions: [],
      headers: [
        { text: this.$t("global.concepts.version"), value: "version" },
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.created-on"), value: "createdOn" },
        { text: this.$t("global.concepts.created-by"), value: "createdBy" },
      ],
      items: [],
      busy: true,
      showEdit: false,
      valid: false,
    };
  },

  computed: {
    details() {
      return (
        this.$t("global.concepts.details") +
        " version " +
        (this.selectedVersion?.versionNumber + 1)
      );
    },
  },

  methods: {
    formatDate,
    listTasks() {
      this.$router.push({ name: "Process tasks" });
    },

    onInput(data) {
      this.formData = data;
    },

    async getTaskVersions(id) {
      return await get("/flow-task-config/" + id + "/versions").catch(
        (error) => {
          this.$store.commit("showError", error);
        }
      );
    },

    async deleteTask() {
      await remove("/flow-task-config/" + this.$route.params.taskId)
        .then(() => {
          this.$router.push({ name: "Process tasks" });
        })
        .catch((e) => {
          this.$store.commit(
            "showError",
            this.$t("global." + e.message, e.arguments)
          );
        });
    },

    async editTask() {
      var data = { ...this.formData };

      if (this.formData.responsibles)
        data.responsibles = this.formData.responsibles.join(";");

      await put("/flow-task-config/" + this.$route.params.taskId, data)
        .then(() => {
          this.showEdit = false;
          this.loadData();
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    onEdit() {
      this.showEdit = true;
    },

    cancelEdit() {
      this.showEdit = false;
    },

    onRowClick(record) {
      this.selectedVersion = record;
      this.initFormData = this.createFormData(this.selectedVersion);
    },

    createFormData(taskVersion) {
      const formData = {};

      formData.name = taskVersion.name;
      formData.type = taskVersion.type;

      const jsonConfig = JSON.parse(taskVersion.jsonConfig);
      formData.module = jsonConfig.moduleName;
      formData.moduleVersion = jsonConfig.moduleVersion;
      formData.simulation = jsonConfig.simulationNames
        ? jsonConfig.simulationNames[0]
        : "";
      formData.title = jsonConfig.title;
      formData.title = jsonConfig.title;
      formData.autoPublish = jsonConfig.autoPublish;
      formData.startRun = jsonConfig.startRun;
      formData.propertiesText = jsonConfig.runProperties;
      formData.fileNames = jsonConfig.fileNames;
      formData.evaluationMessage = jsonConfig.evaluationMessage;
      formData.responsibles = taskVersion.responsibles?.split(";");

      return formData;
    },

    async loadData() {
      this.busy = true;
      const taskId = this.$route.params.taskId;

      this.taskVersions = await this.getTaskVersions(taskId);

      if (this.taskVersions) {
        this.items = [];
        this.taskVersions.forEach((value) => {
          const item = value;

          item[this.headers[0].value] = value.versionNumber + 1;
          item[this.headers[2].value] = value.dateCreated;
          item[this.headers[3].value] = value.createdBy.username;

          this.items.push(item);
        });
      }
      this.selectedVersion = this.items ? this.items[0] : undefined;
      this.initFormData = this.createFormData(this.selectedVersion);
      this.busy = false;
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>
