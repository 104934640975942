<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.business-process.customize-image')"
  >
    <v-row dense>
      <v-col cols="3">
        <ooliba-basic-table
          v-if="positions"
          :items="positions"
          :headers="headers"
          :selected.sync="selectedPosition"
          item-key="vertexId"
          no-pagination
          @row-clicked="onRowClick"
        ></ooliba-basic-table>
      </v-col>
      <v-col>
        <BpDefinitionPositioningImage
          v-if="positions && selectedPosition"
          :selected-position="selectedPosition"
          :positions="positions"
          :flow-id="bpVersion.flowId"
          :flow-user-image-id="bpVersion.flowUserImageId"
          :flow-user-image-width="bpVersion.flowUserImageWidth"
          :flow-user-image-height="bpVersion.flowUserImageHeight"
          @return="onReturn"
          @change="onChange"
          @revert="initPositions"
        />
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import BpDefinitionPositioningImage from "@/components/businessProcess/bpDefinition/BpDefinitionPositioningImage.vue";
import { get } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";

export default {
  name: "CustomizeBPDefinitionImage",

  components: {
    ModuleTemplate,
    BpDefinitionPositioningImage,
    OolibaBasicTable,
  },

  data() {
    return {
      bpVersion: {},
      positions: [],
      selectedPosition: undefined,

      headers: [
        { text: this.$t("global.business-process.tasks"), value: "alias" },
      ],
    };
  },

  methods: {
    async getBpVersion(id) {
      return await get("/business-process-version/" + id).catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    onReturn() {
      this.$router.push({
        name: "BP definition",
        params: { bpId: this.bpVersion.bpId },
      });
    },

    onChange(newPosition) {
      this.selectedPosition = newPosition;
    },

    async getPositions(flowId) {
      return await get("/flow/positions/" + flowId).catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    onRowClick(item) {
      this.selectedPosition = item;
    },

    async initPositions() {
      if (this.bpVersion) {
        this.positions = await this.getPositions(this.bpVersion.flowId);
        this.selectedPosition = this.positions[0];
      }
    },
  },

  async created() {
    this.bpVersion = await this.getBpVersion(
      parseInt(this.$route.params.bpVersionId)
    );

    this.initPositions();
  },
};
</script>
