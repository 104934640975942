<template>
  <iframe :src="reportPath" width="100%" height="1000px;"></iframe>
</template>

<script>
export default {
  name: "RunReport",
  props: {
    runReport: Object,
  },
  data() {
    return {
      reportPath: "",
    };
  },
  created() {
    this.reportPath =
      "/compliance-server/api/simulationRun/run-report/" +
      this.runReport.workerId +
      "/" +
      this.runReport.id +
      "/index.html";
  },
};
</script>
