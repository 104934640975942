<template>
  <create-edit-role cancelRoute="Roles" />
</template>

<script>
import CreateEditRole from "@/components/security/CreateEditRole";

export default {
  name: "CreateRole",
  components: {
    CreateEditRole,
  },
};
</script>
