<template>
  <v-menu
    :disabled="localDisabled"
    class="effisoft-menu"
    open-on-hover
    offset-y
  >
    <template #activator="{ on }">
      <!-- bouton d'ouverture du menu
           expanderMode => Add the effisoft-menu-item class for icon alignment -->
      <div
        v-on="on"
        :class="expanderMode ? 'effisoft-menu-item' : ''"
        :style="!!label ? 'max-width:600px;height:100%' : ''"
      >
        <v-chip
          v-show="label"
          :dark="darkLabel"
          :id="id"
          :disabled="localDisabled"
        >
          {{ label }}
          <v-avatar>
            <v-icon>{{ icon }}</v-icon>
          </v-avatar>
        </v-chip>

        <template v-if="!label">
          <v-tooltip v-if="tooltip" top>
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  :color="color"
                  :disabled="localDisabled"
                  :id="id"
                  icon
                  text
                  depressed
                >
                  <v-icon>{{ icon }}</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ tooltip }}</span>
          </v-tooltip>

          <v-btn
            v-else
            :color="color"
            :disabled="localDisabled"
            :id="id"
            icon
            text
          >
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </template>
      </div>
    </template>

    <!-- contenu du menu -->
    <v-list dense class="effisoft-menu-container">
      <!-- Deprecated -->
      <v-list-item
        v-for="(item, i) in items"
        target="_blank"
        :href="item.href"
        :key="item.id ? item.id : i"
        @click="onClickItem(item)"
      >
        <v-list-item-title
          :class="{ 'grey--text': item.disabled }"
          :disabled="item.disabled"
          :id="item.id"
          ripple
        >
          {{ item.label }}
        </v-list-item-title>
      </v-list-item>

      <!-- Use slot with Effisoft Menu -->
      <slot></slot>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "effisoft-menu",
  props: {
    color: {
      type: String,
    },
    // Désactive le menu
    // Il est automatiquement désactivé s'il ne contient aucun élément
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Set to true if use in an expander
     */
    expanderMode: Boolean,
    // Nom de l'icône à afficher
    // Pour la liste des icônes disponibles, voir https://material.io/resources/icons/
    icon: {
      type: String,
      default: "pic-dot-menu-fill",
    },
    // identifiant HTML du bouton d'activation du menu
    id: {
      type: String,
      required: false,
    },
    // Libellé à afficher après l'icône
    label: {
      type: String,
      required: false,
    },

    darkLabel: {
      type: Boolean,
      default: false,
    },

    // Actions proposées dans le menu
    // C'est un tableau d'objets, avec pour chaque élement :
    //  label - string : libellé de l'action dans la langue de l'utilisateur (obligatoire)
    //  href - string : URL à laquelle accéder lorsque l'utilisateur clique sur l'action
    //  onClick - callback : fonction à appeller lorsque l'utilisateur clique sur l'action
    //  disabled - bool : désactive l'élément. Les éléments sans href ni onClick sont toujours désactivés.
    //  id - string : identifiant HTML, unique dans toute la page (facultatif)
    items: {
      type: Array,
      required: false,
    },
    // Texte de l'infobulle à afficher lorsque l'utilisateur passe la souris sur cet élément du menu
    // ou null si pas d'infobulle
    tooltip: {
      type: String,
      required: false,
    },
  },
  computed: {
    // Indique si le bouton d'ouverture du menu doit être désactivé
    localDisabled() {
      return this.disabled; // || (!this.items.length && !this.$slots.default)
    },
  },
  mounted() {
    if (this.items) {
      console.warn(
        "EffisoftMenu : prop 'items' is deprecated, use EffisoftMenuItem components in default slot instead."
      );
    }
  },
  methods: {
    // Appellée quand l'utilisateur clique sur un élement du menu
    // Sert seulement à contourner le fait que v-on n'accepte pas les valeurs nulles,
    // puisque item.onClick n'est pas obligatoire (le clic produit quand même un effet si item.href est renseignée).
    onClickItem(item) {
      if (item.onClick) item.onClick();
    },
  },
};
</script>
