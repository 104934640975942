<template>
  <v-card class="mx-auto">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-actions>
      <v-btn @click="onCancel">{{ $t("global.action.cancel") }}</v-btn>
      <v-btn @click="onConfirm" color="primary">{{
        $t("global.action.yes")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmAlert",

  props: {
    message: {
      type: String,
    },
  },

  computed: {
    title() {
      return this.message ?? this.$t("global.action.confirm-message");
    },
  },

  methods: {
    onCancel(event) {
      this.$emit("cancel", event);
    },
    onConfirm(event) {
      this.$emit("confirm", event);
    },
  },
};
</script>
