<template>
  <div class="ooliba-basic-table">
    <!-- Line 1 -->
    <v-row dense>
      <!-- switch tree -->
      <v-col v-if="showLeftPanelBtn" cols="auto" align-self="center">
        <basic-tooltip :text="panelToolTip">
          <v-btn-toggle v-model="treeEnabled" color="primary" rounded dense>
            <v-btn :value="true" primary small text>
              <v-icon v-if="!treeEnabled">mdi-book-open-outline</v-icon>
              <v-icon v-else>mdi-book-open</v-icon>
            </v-btn>
          </v-btn-toggle>
        </basic-tooltip>
      </v-col>

      <!-- Filter part -->
      <v-col align-self="center" v-if="showFilterMenu">
        <template>
          <v-row dense>
            <!-- Filters -->
            <v-col
              v-for="headerFilter in headersFiltered"
              :key="headerFilter.text"
              align-self="center"
              cols="auto"
            >
              <checkbox-list
                v-model="headerFilter.itemsSelected"
                :items="headerFilter.itemFilters"
                :label="headerFilter.text"
                filter-icon
                search
                small
                @input="onFilterChange(headerFilter, $event)"
              />
            </v-col>

            <!-- Filter More -->
            <v-col align-self="center" cols="auto">
              <basic-tooltip
                :text="$t('global.action.add-remove-filters')"
                v-if="showFilterMenu"
              >
                <checkbox-list
                  :items="headersFilterOpt"
                  append-icon="mdi-filter-plus-outline"
                  item-value="showFilter"
                  :label="$t('global.action.more')"
                  small
                  @input="onShowHeadersFilterOptChange"
                />
              </basic-tooltip>
            </v-col>

            <!-- clear filter -->
            <v-col v-if="filterEnabled" align-self="center" cols="auto">
              <basic-tooltip :text="$t('global.action.clear-filters')">
                <v-btn dark small @click="onClearFilter">
                  <v-icon small>mdi-filter-remove</v-icon>
                </v-btn>
              </basic-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-col>

      <!-- Right part -->
      <v-col
        align-self="center"
        v-if="canDelete || canCreate || showSearchBar || showColumnMenu"
      >
        <v-row justify="end" dense>
          <!-- delete btn -->
          <v-col
            v-if="canDelete && selectedRows.length"
            align-self="center"
            cols="auto"
          >
            <basic-tooltip :text="deleteAllToolTip">
              <menu-confirm
                :disabled="disabled"
                :text="deleteMsg"
                btn-dark
                btn-icon="pic-delete"
                btn-small
                color="red"
                :text-no="$t('global.action.no')"
                :text-yes="$t('global.action.yes')"
                @yes="onDelete"
              />
            </basic-tooltip>
          </v-col>

          <!-- New btn -->
          <v-col align-self="center" cols="auto" v-if="canCreate">
            <v-btn @click="onCreate" small color="primary">{{
              $t("global.action.new")
            }}</v-btn>
          </v-col>

          <!-- Search bar -->
          <v-col cols="6" v-if="showSearchBar">
            <v-text-field
              class="ooliba-text-search"
              v-model="search"
              append-icon="mdi-magnify"
              dense
              hide-details
              :label="$t('global.action.search')"
              outlined
              single-line
              persistent-placeholder
            />
          </v-col>

          <!-- Btn show/hide columns -->
          <v-col align-self="center" cols="auto">
            <basic-tooltip
              v-if="showColumnMenu"
              :text="$t('global.action.show-hide-columns')"
            >
              <checkbox-list
                :items="headersShowable"
                append-icon="mdi-table-cog"
                item-value="show"
                small
                @input="onShowColumnChange"
              />
            </basic-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Line 2 -->
    <v-row dense>
      <v-col cols="auto" class="pr-0">
        <v-card v-if="treeEnabled || slottedLeftPanel" color="#00000000">
          <v-card-title
            class="px-2 py-0 ooliba-basic-table-panel-header"
            v-if="!slottedLeftPanel"
          >
            <v-switch
              v-if="!slottedLeftPanel"
              class="my-0"
              v-model="panelFilterEnabled"
              dark
              dense
              :label="$t('global.action.tree-mode')"
              hide-details
            />
          </v-card-title>

          <v-card-text
            v-if="panelFilterEnabled || slottedLeftPanel"
            class="px-0 py-0"
          >
            <slot v-if="slottedLeftPanel"></slot>
            <!-- to set heigh with scroll <div style="height: 150px" class="overflow-auto"> -->
            <v-treeview
              :active.sync="treeItemSelected"
              :open.sync="treeFolderOpen"
              :items="treeItems"
              active-class="ooliba-basic-table-tree-item-active"
              activatable
              dense
              hoverable
              open-on-click
              transition
            />
          </v-card-text>

          <template v-else>
            <v-list dense>
              <v-list-item
                v-for="filter in headersTree"
                :key="filter.text"
                dense
              >
                <checkbox-list
                  v-model="filter.itemsSelected"
                  :items="filter.itemFilters"
                  :label="filter.text"
                  filter-icon
                  search
                  small
                  @input="onPanelFilterChange(filter, $event)"
                />
              </v-list-item>
            </v-list>
          </template>
        </v-card>
      </v-col>

      <!-- to not changed line on v-data-table  -->
      <v-col class="fill-width pb-2 px-1">
        <div ref="grid">
          <v-data-table
            :class="`elevation-1 ${classComputed}`"
            v-model="selectedRows"
            :value="value"
            @input="$emit('input', $event)"
            :disabled="disabled"
            :disable-pagination="noPagination"
            :headers="headersDisplayed"
            :height="heightLocal"
            :hide-default-footer="noPagination"
            :item-key="itemKey"
            :items="itemsLocal"
            :items-per-page="itemsPerPageLocal"
            :loading="loading"
            :search="search"
            :show-select="showSelect"
            :single-select="singleSelect"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :must-sort="mustSort"
            dense
            @click:row="onRowClick"
            @item-selected="onItemSelect"
            :options.sync="options"
            :server-items-length="serverItemsLength"
            :footer-props="{
              itemsPerPageText: $t(
                'global.vuetify.dataIterator.rowsPerPageText'
              ),
              pageText: $t('global.vuetify.dataIterator.pageText'),
              itemsPerPageOptions: [25, 50, 100],
            }"
          >
            <!-- Link all template item.<column> to our custom slot item.<column>  -->
            <template
              v-for="header in headers"
              #[`item.${header.value}`]="{ item }"
            >
              <slot :name="`item.${header.value}`" :item="item">
                {{ item[header.value] }}
              </slot>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicTooltip from "@/components/BasicTooltip";
import CheckboxList from "@/components/CheckboxList";
import MenuConfirm from "@/components/MenuConfirm";

export default {
  components: {
    BasicTooltip,
    CheckboxList,
    MenuConfirm,
  },

  props: {
    canCreate: Boolean,
    canDelete: Boolean,

    disabled: Boolean,

    // the array of seleced rows, it's called value so it can be used with v-model
    value: Array,

    /**
     * Filter to apply by default example: { columnValue: ['value1', 'value2'] }
     * example: { title: ["test3"] }
     */
    filterList: {
      type: Object,
      default: () => {
        return {};
      },
    },

    /**
     * header option (without vuetify):
     * - class: String - Header class to used (will overwrite default class ooliba-basic-table-header)
     * - hidden: Boolean - To hide the column at start
     * - noFilterable: Boolean - To disable filter
     * - noHideable: Boolean - To disable show/hide option
     * - showFilter: Boolean - To show filter
     * - treeFilter: Boolean - To define a filter for the tree mode (will apply the order level tree by index)
     */
    headers: Array,

    /**
     * header class to use by default
     */
    headersClassDefault: {
      type: String,
      default: "ooliba-basic-table-header",
    },

    height: [String, Number],

    itemKey: String,
    items: Array,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    loading: Boolean,
    noPagination: Boolean,
    numberItemShow: {
      type: [Number, String],
      default: 200,
    },
    showColumnMenu: Boolean,
    showFilterMenu: Boolean,
    showSearchBar: Boolean,
    showSelect: Boolean,
    showLeftPanelBtn: Boolean,

    singleSelect: Boolean,
    selected: Object,

    sortBy: String,
    sortDesc: Boolean,
    mustSort: Boolean,

    title: String,

    /**
     * To show a list of deleted items example 'title' => show the list of titles to delete during confirm msg
     */
    tostringProp: String,

    // If the content of the left panel is provided using the slot
    slottedLeftPanel: Boolean,

    selectItems: Array,
    selectedItem: String,
    selectLabel: String,

    serverSide: Boolean,
    serverItemsLength: Number,
  },

  data() {
    return {
      filterListLocal: {},

      headersDisplayed: [],
      headersLocal: [],

      itemsLocal: [],
      itemsFiltered: [],

      panelFilterEnabled: false,

      search: "",
      selectedRows: [],

      tree: [],
      treeEnabled: false,
      treeFolderOpen: [],
      treeItemSelected: [],
      treeMap: {},

      options: {},
    };
  },

  computed: {
    panelToolTip() {
      return this.treeEnabled
        ? this.$t("global.action.close-left-panel")
        : this.$t("global.action.open-left-panel");
    },

    deleteAllToolTip() {
      return (
        this.$t("global.action.delete-all") + ": " + this.selectedRows.length
      );
    },

    classComputed() {
      let classComputed = "";
      if (this.showSelect) {
        classComputed += " ooliba-basic-table-selection-mode";
      }

      if (this.noPagination) {
        classComputed += " ooliba-basic-table-no-pagination";
      }
      return classComputed;
    },

    deleteMsg() {
      let msg = this.$t("global.action.delete-element");
      if (this.tostringProp && this.selectedRows) {
        let itemsToDeleteString = this.selectedRows
          .map((row) => row[this.tostringProp])
          .join(", ");
        msg += ` (${itemsToDeleteString})`;
      }
      return msg;
    },

    filterEnabled() {
      return !!Object.keys(this.filterListLocal).length;
    },

    filterTriggered() {
      // the filter need to apply when :
      // => treeFilterList changed
      // => filterList changed
      // => headersFiltered
      const value = {
        treeFilterList: this.treeFilterList,
        filterList: this.filterListLocal,
        headersFiltered: this.headersFiltered,
      };

      return value;
    },

    headersFiltered() {
      // When headersDisplayed changed => redefined the filter
      let headersFiltered = this.headersDisplayed
        .filter((h) => h.showFilter)
        .map((h) => {
          return { ...h };
        });

      this.initHeadersFiltered(headersFiltered);

      return headersFiltered;
    },

    headersFilterOpt() {
      return this.headersDisplayed.filter((h) => h.showFilterOpt);
    },

    headersShowable() {
      return this.headersLocal.filter((h) => h.showHideOpt);
    },

    headersTree() {
      // When headersLocal changed => redefined the filter
      let hs = this.headersLocal
        .filter((h) => h.treeFilter)
        .map((h) => {
          return { ...h };
        });

      this.initHeadersFiltered(hs);

      return hs;
    },

    heightLocal() {
      return this.noPagination &&
        !this.height &&
        this.items.length > Number(this.numberItemShow)
        ? 32 * Number(this.numberItemShow) + 32 // size of a dense line 32px; size of the header 32px
        : this.height;
    },

    itemsPerPageLocal() {
      return this.noPagination ? -1 : this.itemsPerPage;
    },

    /**
     * A Tree cache use to filter
     */
    treeFilterList() {
      let treeFilterList = {};
      if (this.treeItemSelected.length) {
        let pathList = this.treeMap[this.treeItemSelected];

        for (let path of pathList) {
          treeFilterList[path.column] = [path.value];
        }
      }
      return treeFilterList;
    },

    treeItems() {
      if (this.slottedLeftPanel) {
        return [];
      }

      let treeItems = [];

      treeItems = this.getTreeItemByIndex(
        0,
        this.headersTree,
        this.items,
        treeItems
      );

      return treeItems;
    },
  },

  watch: {
    filterList: {
      handler() {
        this.filterListLocal = this.filterList;
      },
      immediate: true,
    },

    filterTriggered() {
      let itemsToDisplay = this.items;
      if (Object.keys(this.treeFilterList).length) {
        /*
        console.log(
          "apply panel filter",
          JSON.parse(JSON.stringify(this.treeFilterList))
        );
        */

        itemsToDisplay = this.applyFilter(itemsToDisplay, this.treeFilterList);
      }
      if (Object.keys(this.filterListLocal).length) {
        /*
        console.log(
          "apply filter",
          JSON.parse(JSON.stringify(this.filterListLocal))
        );
        */

        itemsToDisplay = this.applyFilter(itemsToDisplay, this.filterListLocal);
      }

      this.itemsLocal = itemsToDisplay;
    },

    headers: {
      handler() {
        // Init local header (with props and reference init)
        this.headersLocal = this.headers.map((header) => {
          // get default header class
          let hclass = header.class ? header.class : this.headersClassDefault;

          return {
            ...header,
            class: hclass,
            show: !header.hidden, // by default all column is show
            showFilter: !!header.showFilter,
            showFilterOpt: !header.noFilterable && !header.treeFilter,
            showHideOpt: !header.noHideable,
          };
        });
      },
      immediate: true,
    },

    headersLocal: {
      deep: true,
      handler() {
        // Computed the header to display from headersLocal when changed (deep)
        this.headersDisplayed = this.headersLocal.filter((h) => h.show);
      },
      immediate: true,
    },

    items() {
      this.selectedRows = []; // Reset selection  when new items
      this.itemsLocal = this.items;
    },

    panelFilterEnabled() {
      if (this.panelFilterEnabled) {
        this.treeItemSelected = [];
      } else {
        // remove all panel filter
        this.clearPanelFilter();
      }
    },

    treeEnabled() {
      if (!this.treeEnabled) {
        this.treeItemSelected = [];
      }
    },

    selected() {
      this.selectedRows = [this.selected];
    },

    options: {
      handler() {
        this.$emit("options-changed", this.options);
      },
      deep: true,
    },
  },

  methods: {
    applyFilter(items, filterList) {
      let itemsToDisplay = [];
      for (let item of items) {
        let itemIsValid = this.checkFilterOnItem(item, filterList);
        if (itemIsValid) {
          itemsToDisplay.push(item);
        }
      }
      return itemsToDisplay;
    },

    clearPanelFilter() {
      let filterListToUpdate = { ...this.filterListLocal };
      for (let headerTree of this.headersTree) {
        delete filterListToUpdate[headerTree.value];
      }
      this.filterListLocal = filterListToUpdate;
    },

    checkFilterOnItem(item, filterList) {
      let lineFilterIsValid = true;
      for (let column in filterList) {
        let textList = filterList[column];
        const columnFilterIsValid = textList.some(
          (text) => text === item[column]
        );
        lineFilterIsValid = lineFilterIsValid && columnFilterIsValid;
        if (!lineFilterIsValid) {
          return lineFilterIsValid;
        }
      }
      return lineFilterIsValid;
    },

    getTreeItemByIndex(
      index,
      headerTree,
      items,
      treeItems = [],
      parentList = []
    ) {
      let column = headerTree[index].value;
      for (let item of items) {
        let treeItem = treeItems.find((t) => t.name === item[column]);
        if (!treeItem) {
          const parentValueList = parentList.map((p) => p.value);
          const key = `${parentValueList.join("")}${item[column]}`;

          treeItem = {
            id: key,
            name: item[column],
          };
          let parent = {
            column,
            value: item[column],
          };
          // Compute children
          if (index < headerTree.length - 1) {
            treeItem.children = this.getTreeItemByIndex(
              index + 1,
              headerTree,
              items.filter((child) => child[column] === item[column]),
              [],
              [...parentList, parent]
            );
          } else {
            // Last level from tree => Update treeMap (will be use to filter)
            this.treeMap[key] = [...parentList, parent];
          }
          treeItems.push(treeItem);
        }
      }

      // Apply sort
      if (treeItems && treeItems.length) {
        let treeSortDesc = headerTree[index].treeSortDesc;
        treeItems.sort((a, b) =>
          treeSortDesc ? a.name < b.name : a.name > b.name
        );
      }

      return treeItems;
    },

    initHeadersFiltered(headers) {
      for (let header of headers) {
        // Get the disctinct list of choices
        let valuesChoice = [
          ...new Set(this.items.map((item) => item[header.value])),
        ];
        header.itemFilters = valuesChoice; // itemFilters naming (dont use filters because vuetify header has a filter props)
        // Get default selected items
        let itemsSelected = this.filterListLocal[header.value]
          ? this.filterListLocal[header.value]
          : [];
        header.itemsSelected = itemsSelected;
      }

      return headers;
    },

    onClearFilter() {
      for (let headerFiltered of this.headersFiltered) {
        headerFiltered.itemsSelected = [];
      }
      this.filterListLocal = {};
    },

    onCreate(event) {
      this.$emit("create", event);
    },

    onDelete() {
      this.$emit("delete", this.selectedRows);
    },

    onFilterChange(header, listOfFilterApply) {
      this.updateFilterList(header.value, listOfFilterApply);
    },

    onItemSelect(selected) {
      this.$emit("item-select", selected.item);
      this.$emit("update:selected", selected.value ? selected.item : null);
    },

    onPanelFilterChange(header, listOfFilterApply) {
      this.updateFilterList(header.value, listOfFilterApply);
    },

    onRowClick(row) {
      this.$emit("row-clicked", row);
    },

    onShowColumnChange(header) {
      const showColumn = !header.show;

      // clean filter => when removing a filter column which is not a tree filter
      if (!showColumn && !header.treeFilter) {
        this.updateFilterList(header.value, []);
      }

      // Update headersLocal show
      let headerToUpdate = this.headersLocal.find(
        (h) => h.value === header.value
      );
      headerToUpdate.show = showColumn;
    },

    onShowHeadersFilterOptChange(header) {
      const showFilter = !header.showFilter;

      // clean filter => when removing filter
      if (!showFilter) {
        this.updateFilterList(header.value, []);
      }

      // Update headersLocal showFilter
      let headerToUpdate = this.headersLocal.find(
        (h) => h.value === header.value
      );
      headerToUpdate.showFilter = showFilter;
    },

    updateFilterList(headerValue, listOfFilterApply) {
      let filterListToUpdate = { ...this.filterListLocal };
      if (listOfFilterApply.length) {
        filterListToUpdate[headerValue] = listOfFilterApply;
      } else {
        delete filterListToUpdate[headerValue];
      }
      this.filterListLocal = filterListToUpdate;
    },
  },
};
</script>
