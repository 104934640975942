<template>
  <v-card class="mt-2">
    <v-card-text>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <h6 class="py-4">
        {{ taskHeader }}
      </h6>

      <v-alert dense outlined type="error" v-if="showError">
        {{ errorMsg }}
      </v-alert>
      <div v-if="$store.state.bpInstModifyPermission && !locked" class="mb-2">
        <v-btn
          @click="retry"
          :disabled="!allowedActions?.canRetry"
          class="mr-4"
          color="primary"
        >
          <v-icon left> pic-refresh </v-icon>
          {{ $t("global.action.retry") }}
        </v-btn>
        <v-btn
          color="error"
          @click="cancel"
          :disabled="!allowedActions.canCancel"
        >
          <v-icon left> pic-close </v-icon>
          {{ $t("global.action.cancel") }}
        </v-btn>
      </div>
      <div v-if="active">
        <task-run-list
          v-if="taskType === 'runCreation'"
          :flowTaskId="flowTaskId"
        ></task-run-list>
        <file-upload-task
          v-if="taskType === 'fileUpload'"
          :fileEnv="fileEnv"
          :flowTaskId="flowTaskId"
        ></file-upload-task>
        <evaluation-task
          v-if="taskType === 'evaluation'"
          :flowTaskId="flowTaskId"
        ></evaluation-task>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from "@/model/api";
import TaskRunList from "@/components/businessProcess/TaskRunList";
import FileUploadTask from "@/components/businessProcess/FileUploadTask";
import EvaluationTask from "@/components/businessProcess/EvaluationTask";

export default {
  name: "TaskDetails",
  components: {
    TaskRunList,
    FileUploadTask,
    EvaluationTask,
  },
  props: {
    flowTaskId: Number,
    name: String,
    fileEnv: String,
    locked: String,
  },
  data() {
    return {
      allowedActions: {},
      taskType: "",
      showError: undefined,
      errorMsg: undefined,

      active: false,
      loading: false,
      actionsTimerId: undefined,
      statusTimerId: undefined,
    };
  },
  computed: {
    taskHeader() {
      return this.$t("global.business-process.task") + ": " + this.name;
    },
  },
  methods: {
    async getStatus() {
      return await get("/flow-task/" + this.flowTaskId + "/status").catch(
        (error) => this.onError(error)
      );
    },
    async getAllowedActions() {
      return await get(
        "/flow-task/" + this.flowTaskId + "/allowed-actions"
      ).catch((error) => this.onError(error));
    },

    async getTaskType() {
      return await get("/flow-task/" + this.flowTaskId + "/type").catch(
        (error) => this.onError(error)
      );
    },

    async getTrigger() {
      return await get("/flow-task/" + this.flowTaskId + "/trigger").catch(
        (error) => this.onError(error)
      );
    },

    async retry() {
      await get("/flow-task/" + this.flowTaskId + "/invalidate")
        .then(() => {
          this.showTaskWhenStatusEquals([
            "RUNNING",
            "WAITING_FOR_INPUT",
            "READY_TO_RUN",
          ]);
          this.allowedActions = this.getAllowedActions();
        })
        .catch((error) => this.onError(error));
    },

    async cancel() {
      await get("/flow-task/" + this.flowTaskId + "/cancel")
        .then(() => {
          this.showTaskWhenStatusEquals(["CANCELLED"]);
          this.allowedActions = this.getAllowedActions();
        })
        .catch((error) => this.onError(error));
    },
    onError(error) {
      this.$store.commit("showError", error);
    },

    continuouslyUpdateAllowedActions() {
      this.actionsTimerId = window.setTimeout(() => {
        this.getAllowedActions().then((res) => {
          this.allowedActions = res;
        });
        this.continuouslyUpdateAllowedActions();
      }, 2200);
    },

    showTaskWhenStatusEquals(statuses) {
      this.loading = true;

      this.statusTimerId = window.setTimeout(() => {
        this.getStatus().then((status) => {
          if (status === "ERROR") {
            this.updateData();
            this.loading = false;
          } else {
            if (statuses.some((s) => s === status)) {
              this.updateData();
              this.loading = false;
            } else {
              this.showTaskWhenStatusEquals(statuses);
            }
          }
        });
      }, 300);
    },

    async getRuns() {
      return await get("/flow-task/" + this.flowTaskId + "/runs").catch(
        (error) => {
          this.onError(error);
        }
      );
    },

    async updateData() {
      this.taskType = "";
      this.allowedActions = {};
      const status = await this.getStatus();
      this.active = status !== "PENDING" && status !== "READY_TO_RUN";
      if (status === "ERROR") {
        const runs = await this.getRuns();
        let latestRunStatus;
        if (runs && runs[0]) {
          latestRunStatus = runs[0].status;
        }
        this.showError = !(
          latestRunStatus &&
          (latestRunStatus === "ERROR" || latestRunStatus === "INIT_ERROR")
        );
        this.errorMsg = await this.getTrigger();
      } else {
        this.showError = false;
      }
      if (this.active) {
        this.allowedActions = await this.getAllowedActions();
        this.taskType = await this.getTaskType();
      }
    },
  },
  watch: {
    flowTaskId: async function () {
      await this.updateData();
    },
  },
  async created() {
    await this.updateData();
    this.continuouslyUpdateAllowedActions();
  },
  destroyed() {
    clearTimeout(this.actionsTimerId);
    clearTimeout(this.statusTimerId);
  },
};
</script>
