<template>
  <module-template
    id="scrolltainer"
    title
    canDelete
    canEdit
    canList
    @editItem="editUser"
    @list="listUsers"
    @deleteItem="deleteUser"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.concepts.sysuser") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ user.username }}</div>
      </v-row>
    </template>

    <v-card>
      <v-card-text>
        <basic-card-horizontal :headers="headers" :item="item" :max-width="500">
          <template #[`item.accountEnabled`]="{ item }">
            <v-checkbox
              v-model="item.accountEnabled"
              disabled
              class="pt-0 mt-0"
              hide-details
            ></v-checkbox>
          </template>
          <template #[`item.accountExpired`]="{ item }">
            <v-checkbox
              v-model="item.accountExpired"
              disabled
              class="pt-0 mt-0"
              hide-details
            ></v-checkbox>
          </template>
          <template #[`item.accountLocked`]="{ item }">
            <v-checkbox
              v-model="item.accountLocked"
              disabled
              class="pt-0 mt-0"
              hide-details
            ></v-checkbox>
          </template>
          <template #[`item.passwordExpired`]="{ item }">
            <v-checkbox
              v-model="item.passwordExpired"
              disabled
              class="pt-0 mt-0"
              hide-details
            ></v-checkbox>
          </template>
        </basic-card-horizontal>

        <h6 class="py-4">
          {{ $t("global.menu.administration.roles") }}
        </h6>

        <roles-table v-if="roles" v-bind:roles="roles" />
        <h6 class="py-4">
          {{ $t("global.administration.vaults") }}
        </h6>
        <vault-permissions-table v-if="vaults" :vaultPermissions="vaults" />
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { get, remove } from "@/model/api";
import RolesTable from "@/components/security/RolesTable";
import VaultPermissionsTable from "@/components/security/VaultPermissionsTable";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";

export default {
  name: "SecUser",
  components: {
    ModuleTemplate,
    RolesTable,
    VaultPermissionsTable,
    BasicCardHorizontal,
  },

  data() {
    return {
      user: {},
      vaults: undefined,
      roles: undefined,

      headers: [
        {
          text: this.$t("global.administration.first-name"),
          value: "firstName",
        },
        {
          text: this.$t("global.administration.last-name"),
          value: "lastName",
        },
        {
          text: this.$t("global.administration.email"),
          value: "email",
        },
        {
          text: this.$t("global.administration.account-enabled"),
          value: "accountEnabled",
        },
        {
          text: this.$t("global.administration.account-expired"),
          value: "accountExpired",
        },
        {
          text: this.$t("global.administration.account-locked"),
          value: "accountLocked",
        },
        {
          text: this.$t("global.administration.password-expired"),
          value: "passwordExpired",
        },
      ],
    };
  },

  computed: {
    item() {
      return {
        firstName: this.user?.firstName,
        lastName: this.user?.lastName,
        email: this.user?.email,
        accountEnabled: this.user?.enabled,
        accountExpired: this.user?.accountExpired,
        accountLocked: this.user?.accountLocked,
        passwordExpired: this.user?.passwordExpired,
      };
    },
  },

  methods: {
    async getUser(id) {
      return await get("/user/" + id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    async deleteUser() {
      await remove("/user/" + this.user.id)
        .then(() => {
          this.$router.push({ name: "Users" });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    async getRolesForUser(id) {
      return await get("/user/" + id + "/roles")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async getVaultsForUser(id) {
      return await get("/user/" + id + "/vaults")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    editUser() {
      this.$router.push({
        name: "Edit user",
        params: { userId: this.user.id },
      });
    },
    listUsers() {
      this.$router.push({ name: "Users" });
    },
  },
  async created() {
    const userId = this.$route.params.userId;

    this.user = await this.getUser(userId);
    this.roles = await this.getRolesForUser(userId);
    this.vaults = await this.getVaultsForUser(userId);
  },
};
</script>
