<template>
  <div class="effisoft-menu-item">
    <v-list-item
      v-if="isList"
      :disabled="localDisabled"
      :href="href"
      :key="id"
      target="_blank"
      @click="onClick"
    >
      <v-list-item-content>
        <v-list-item-title :id="id" ripple v-text="label"> </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- icon mode + confirm -->
    <v-tooltip
      v-else-if="confirmMsg"
      :disabled="tooltipDisabled"
      :content-class="contentClass"
      bottom
    >
      <template #activator="{ on }">
        <div v-on="on">
          <compliance-dialog-confirm :text="confirmMsg" @yes="onYes">
            <template #activator="{ on }">
              <v-btn
                :id="id"
                icon
                text
                v-on="on"
                depressed
                :disabled="localDisabled"
                @click="onClick"
              >
                <v-icon>{{ icon }}</v-icon>
              </v-btn>
            </template>
          </compliance-dialog-confirm>
        </div>
      </template>
      <span>{{ localTooltip }}</span>
    </v-tooltip>

    <!-- icon mode only -->
    <v-tooltip
      v-else
      :disabled="tooltipDisabled"
      :content-class="contentClass"
      bottom
    >
      <template #activator="{ on }">
        <div v-on="on">
          <v-btn
            depressed
            :id="id"
            icon
            text
            v-on="on"
            :disabled="localDisabled"
            @click="onClick"
          >
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ localTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import ComplianceDialogConfirm from "@/components/ComplianceDialogConfirm";

/**
 * Action proposée dans un menu déroulant, à utiliser dans les slots prévu à cet effet
 * dans <effisoft-menu> et <effisoft-expander>.
 * Émet :
 *  click() quand l'utilisateur clique sur cette action, et qu'elle n'est pas désactivée (disabled)
 */
export default {
  name: "effisoft-menu-item",

  components: {
    ComplianceDialogConfirm,
  },

  props: {
    confirmMsg: {
      type: String,
    },

    contentClass: {
      type: String,
    },

    // Désactive l'élément : il reste visible mais grisé, et cliquer dessus n'a aucun effet.
    // À utiliser lorsque l'utilisateur a le droit d'effectuer l'action (habilitations),
    // mais que celle-ci n'est pas possible actuellement ou n'aurait aucun effet (données).
    // Les éléments sans URL (href) ni gestionnaire d'évènement 'click' sont toujours désactivés.
    disabled: {
      type: Boolean,
      default: false,
    },
    // URL à laquelle accéder lorsque l'utilisateur clique sur l'action
    href: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: "pic-missing-icon",
    },
    // identifiant HTML de l'élément, unique dans toute la page
    id: {
      type: String,
      required: false,
    },
    isList: {
      type: Boolean,
      default: false,
    },
    // Libellé à afficher, dans la langue de l'utilisateur
    label: {
      type: String,
      required: true,
    },
    // Texte de l'infobulle à afficher lorsque l'utilisateur passe la souris sur cet élément du menu
    // ou null si pas d'infobulle
    tooltip: {
      type: String,
      required: false,
    },
    // Désactive l'affichage de l'infobulle
    tooltipShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showConfirmDialog: false,
    };
  },

  computed: {
    // Indique si l'élément de menu doit être désactivé,
    // c'est à dire si le parent l'a désactivé ou si cliquer dessus n'aurait aucun effet.
    localDisabled() {
      return this.disabled || (!this.href && !this.$listeners.click);
    },
    // Texte de l'infobulle à afficher ou null si pas d'infobulle
    localTooltip() {
      return this.tooltip && this.tooltipShow ? this.tooltip : this.label;
    },
    tooltipDisabled() {
      return this.disabled && !this.tooltipShow;
    },
  },

  methods: {
    onClick() {
      if (this.confirmMsg) {
        this.$emit("click-before-confirm");
      } else {
        this.$emit("click");
      }
    },

    onYes() {
      this.$emit("click");
    },
  },
};
</script>
