<template>
  <div class="mt-3">
    <v-btn @click="onCancel" class="mr-2">{{
      $t("global.action.cancel")
    }}</v-btn>
    <v-btn color="primary" type="submit" :disabled="!canSubmit">
      {{ confirmText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "FormButtons",
  props: {
    confirmText: String,
    canSubmit: { Boolean, default: true },
  },
  methods: {
    onCancel(event) {
      this.$emit("cancel", event);
    },
  },
};
</script>
