<template>
  <div>
    <v-list width="44px" shaped>
      <v-list-item-group
        v-model="selectedRow"
        @change="onChange()"
        active-class="id-list-active"
      >
        <v-list-item
          :style="selectedRow != item.id - 1 ? 'background-color: #edebf5' : ''"
          v-for="item in items"
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.id }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "IdList",
  props: {
    items: [],
    value: Number,
  },
  data() {
    return {
      selectedRow: 0,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedRow = this.value;
      },
    },
  },

  methods: {
    onChange() {
      if (!this.selectedRow || this.selectedRow >= this.items?.length) {
        this.selectedRow = 0;
      }
      this.$emit("input", this.selectedRow);
    },
  },
};
</script>
