<template>
  <div>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.username"
          :label="$t('global.administration.user-name') + '*'"
          :disabled="isEdit"
          :rules="[notEmptyRule, noTagsRule]"
          @input="onInput()"
          outlined
          persistent-placeholder
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <compliance-password
          v-model="formData.password"
          :label="$t('global.pswDialog.psw') + (isEdit ? '' : '*')"
          counter
          id="pswId"
          ref="psw"
          :required="!isEdit"
        /> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.firstName"
          :label="$t('global.administration.first-name')"
          :rules="[noTagsRule]"
          @input="onInput()"
          outlined
          persistent-placeholder
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.lastName"
          :label="$t('global.administration.last-name')"
          @input="onInput()"
          outlined
          :rules="[noTagsRule]"
          persistent-placeholder
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          type="email"
          v-model="formData.email"
          :label="$t('global.administration.email') + '*'"
          :rules="[emailValidRule, noTagsRule, notEmptyRule]"
          @input="onInput()"
          outlined
          persistent-placeholder
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col>
        <v-checkbox
          :label="$t('global.administration.account-locked')"
          @change="onInput($event, option)"
          v-model="formData.accountLocked"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          :label="$t('global.administration.account-expired')"
          @change="onInput($event, option)"
          v-model="formData.accounteExpired"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          :label="$t('global.administration.password-expired')"
          @change="onInput($event, option)"
          v-model="formData.passwordExpired"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          :label="$t('global.administration.account-enabled')"
          @change="onInput($event, option)"
          v-model="formData.enabled"
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { notEmpty, noTags, emailValid } from "@/model/rules";
import CompliancePassword from "@/components/CompliancePassword";

export default {
  name: "UserDetailsForm",

  components: {
    CompliancePassword,
  },

  props: {
    isEdit: Boolean,
    initialValues: Object,
  },
  data() {
    return {
      formData: {
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        accountLocked: false,
        accounteExpired: false,
        passwordExpired: false,
        enabled: true,
      },
      noTagsRule: noTags(this),
      notEmptyRule: notEmpty(this),
      emailValidRule: emailValid(this),
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.formData);
    },
  },
  created() {
    if (this.isEdit && this.initialValues) this.formData = this.initialValues;
  },
};
</script>
