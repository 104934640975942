<template>
  <div>
    <create-run-form v-if="run.id" :run-to-copy="run"></create-run-form>
  </div>
</template>

<script>
import CreateRunForm from "@/components/run/CreateRunForm.vue";
import { get } from "@/model/api";

export default {
  name: "CopyRun",
  components: {
    CreateRunForm,
  },

  data() {
    return {
      run: {},
    };
  },

  methods: {
    async getRun(id) {
      return await get("/simulationRun?id=" + id).catch((e) => this.onError(e));
    },

    onError(error) {
      this.$store.commit("showError", error);
    },
  },

  async created() {
    const runId = this.$route.params.runId;
    this.run = await this.getRun(runId);
  },
};
</script>
