<template>
  <v-container>
    <auto-complete-filter
      style="width: 200px"
      :items="xOptions"
      :label="$t('global.concepts.x-axis')"
      v-model="xAxis"
      @change="onAxisChange"
    ></auto-complete-filter>
    <v-row>
      <v-col>
        <div style="height: 250px"><canvas id="systemChart"></canvas></div>
      </v-col>
      <v-col>
        <div style="height: 250px"><canvas id="serverChart"></canvas></div>
      </v-col>
      <v-col>
        <div style="height: 250px"><canvas id="workerChart"></canvas></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import AutoCompleteFilter from "@/components/AutoCompleteFilter.vue";

export default {
  name: "RunAnalysis",

  components: {
    AutoCompleteFilter,
  },

  props: {
    data: {
      type: String,
      required: true,
    },
  },

  watch: {
    data(newValue) {
      const parsedNewData = JSON.parse(newValue);

      if (parsedNewData.length > this.parsedData.length) {
        this.parsedData = parsedNewData;
        const labels = this.labels();

        this.sysChart.data.labels = labels;
        this.sysChart.data.datasets = this.sysDataSets();
        this.sysChart.update();

        this.servChart.data.labels = labels;
        this.servChart.data.datasets = this.servDataSets();
        this.servChart.update();

        this.workChart.data.labels = labels;
        this.workChart.data.datasets = this.workDataSets();
        this.workChart.update();
      }
    },
  },

  data() {
    return {
      xAxis: this.$t("global.concepts.time"),
      xOptions: [
        this.$t("global.concepts.time"),
        this.$t("global.concepts.status"),
      ],

      sysChart: null,
      servChart: null,
      workChart: null,

      parsedData: [],
    };
  },

  methods: {
    onAxisChange() {
      const labels = this.labels();

      this.sysChart.data.labels = labels;
      this.sysChart.update();

      this.servChart.data.labels = labels;
      this.servChart.update();

      this.workChart.data.labels = labels;
      this.workChart.update();
    },

    labels() {
      return this.parsedData.map((d) => {
        if (this.xAxis === this.$t("global.concepts.status")) {
          return d.status.substring(0, 3);
        }
        return d.time + " s";
      });
    },

    sysDataSets() {
      return [
        {
          label: this.$t("global.concepts.available-param", [
            this.$t("global.concepts.system"),
          ]),
          data: this.parsedData.map((d) => d.systemTotal),
          borderWidth: 1,
        },
        {
          label: this.$t("global.concepts.used-param", [
            this.$t("global.concepts.system"),
          ]),
          data: this.parsedData.map((d) => d.systemUsed),
          borderWidth: 1,
        },
      ];
    },

    servDataSets() {
      return [
        {
          label: this.$t("global.concepts.max-param", [
            this.$t("global.concepts.server"),
          ]),
          data: this.parsedData.map((d) => d.serverMax),
          borderWidth: 1,
        },
        {
          label: this.$t("global.concepts.allocated-param", [
            this.$t("global.concepts.server"),
          ]),
          data: this.parsedData.map((d) => d.serverTotal),
          borderWidth: 1,
        },
        {
          label: this.$t("global.concepts.used-param", [
            this.$t("global.concepts.server"),
          ]),
          data: this.parsedData.map((d) => d.serverUsed),
          borderWidth: 1,
        },
      ];
    },

    workDataSets() {
      return [
        {
          label: this.$t("global.concepts.max-param", [
            this.$t("global.concepts.worker"),
          ]),
          data: this.parsedData.map((d) => d.workerMax),
          borderWidth: 1,
        },
        {
          label: this.$t("global.concepts.allocated-param", [
            this.$t("global.concepts.worker"),
          ]),
          data: this.parsedData.map((d) => d.workerTotal),
          borderWidth: 1,
        },
        {
          label: this.$t("global.concepts.used-param", [
            this.$t("global.concepts.worker"),
          ]),
          data: this.parsedData.map((d) => d.workerUsed),
          borderWidth: 1,
        },
      ];
    },
  },

  mounted() {
    if (!this.data) {
      return;
    }
    this.parsedData = JSON.parse(this.data);

    const sysCtx = document.getElementById("systemChart");
    if (!sysCtx) {
      return;
    }

    const labels = this.labels();

    this.sysChart = new Chart(sysCtx, {
      type: "line",
      data: {
        labels: labels,
        datasets: this.sysDataSets(),
      },
      options: {
        animation: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value} MB`,
            },
          },
        },
      },
    });

    const servCtx = document.getElementById("serverChart");
    if (!servCtx) {
      return;
    }

    this.servChart = new Chart(servCtx, {
      type: "line",
      data: {
        labels: labels,
        datasets: this.servDataSets(),
      },
      options: {
        animation: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value} MB`,
            },
          },
        },
      },
    });

    const workCtx = document.getElementById("workerChart");
    if (!workCtx) {
      return;
    }

    this.workChart = new Chart(workCtx, {
      type: "line",
      data: {
        labels: labels,
        datasets: this.workDataSets(),
      },
      options: {
        animation: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value} MB`,
            },
          },
        },
      },
    });
  },
};
</script>
