<template>
  <create-edit-user
    :isEdit="true"
    :userId="this.$route.params.userId"
    :cancelRoute="'User'"
  />
</template>

<script>
import CreateEditUser from "@/components/security/CreateEditUser";

export default {
  name: "EditUser",
  components: {
    CreateEditUser,
  },
};
</script>
