<template>
  <v-chip v-if="label === 'dft '" small outlined>{{
    $t("global.concepts.default")
  }}</v-chip>
  <v-chip v-else :color="colorHex" dark small>{{ label }}</v-chip>
</template>

<script>
export default {
  name: "FileEnvChip",

  props: {
    color: String,
    label: String,
  },

  computed: {
    colorHex() {
      switch (this.color) {
        case "GREEN":
          return "#468847";
        case "ORANGE":
          return "#cc6216";
        case "RED":
          return "#b94a48";
        case "BLUE":
          return "#3a87ad";
        case "BLACK":
          return "#333";
        default:
          return "#999";
      }
    },
  },
};
</script>
