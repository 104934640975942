<template>
  <!-- Set prop eager to allow initialize the v-combobox ref -->
  <v-menu
    :close-on-content-click="false"
    :max-height="maxHeight"
    :min-width="minWidth"
    eager
    offset-y
  >
    <template #activator="{ on, attrs }">
      <!-- Apply default filter icon -->
      <v-btn
        v-if="filterIcon"
        v-bind="attrs"
        v-on="on"
        :class="select.length ? 'ooliba-btn-selected' : ''"
        :small="small"
        @click="onBtnClick"
      >
        {{ label }}
        <v-icon v-if="select.length" :right="!!label">mdi-filter-check</v-icon>
        <v-icon v-else :right="!!label">mdi-filter-menu-outline</v-icon>
      </v-btn>

      <v-btn v-else v-bind="attrs" v-on="on" :small="small" @click="onBtnClick">
        {{ label }}
        <v-icon v-if="appendIcon" :right="!!label"> {{ appendIcon }}</v-icon>
      </v-btn>
    </template>

    <!-- search mode -->
    <v-combobox
      v-show="search"
      ref="comboSelect"
      class="white"
      v-model="select"
      :dense="dense"
      :items="items"
      autofocus
      bg-color="white"
      chips
      closable-chips
      clearable
      hide-details
      multiple
      solo
      @input="$emit('input', $event)"
    >
      <!-- if you want a valid btn 
      <template #append>
        <v-btn :disabled="!select.length" color="green" icon small @click="$emit('input', select)"">
          <v-icon> mdi-check </v-icon>
        </v-btn>
      </template>
      -->
    </v-combobox>

    <!-- normal mode -->
    <v-list v-if="!search" :dense="dense">
      <v-list-item v-for="item in items" :key="item[itemText]" :dense="dense">
        <v-checkbox
          :input-value="item[itemValue]"
          :dense="dense"
          :label="item[itemText]"
          @change="$emit('input', item)"
        ></v-checkbox>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    appendIcon: String,
    dense: {
      type: Boolean,
      default: true,
    },
    filterIcon: Boolean,
    itemValue: {
      type: String,
      default: "value",
    },
    itemText: {
      type: String,
      default: "text",
    },
    items: Array,
    label: String,
    maxHeight: {
      type: String,
      default: "240",
    },
    minWidth: {
      type: String,
      default: "240",
    },
    search: Boolean,
    small: Boolean,
    value: [Object, Array],
  },

  data() {
    return {
      select: [],
    };
  },

  watch: {
    value: {
      handler() {
        // console.log("watch checkboxlist value", this.value);
        this.select = this.value;
      },
      immediate: true,
    },
  },

  methods: {
    onBtnClick(e) {
      if (this.search) {
        // When click on the menu => Open the combo list choice
        this.$refs.comboSelect.onClick(e);
      }
    },
  },
};
</script>
