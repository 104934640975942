<template>
  <div class="mx-3">
    <v-row v-for="option in allOptions" :key="option">
      <v-checkbox
        :label="option"
        :disabled="!editable"
        @change="onChange($event, option)"
        :input-value="inputValue(option)"
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CheckBoxGroup",
  props: {
    initSelected: Array,
    allOptions: Array,
    editable: Boolean,
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    onChange(event, option) {
      const selectedContainsOption = this.selected.includes(option);

      if (event && !selectedContainsOption) {
        this.selected.push(option);
      }
      if (!event && selectedContainsOption) {
        this.selected = this.selected.filter((el) => el !== option);
      }
      this.$emit("input", this.selected);
    },
    inputValue(option) {
      return this.initSelected.includes(option);
    },
  },
  created() {
    this.selected = this.initSelected;
  },
};
</script>
