<template>
  <div>
    <h6 class="py-4">
      {{ $t("global.business-process.tasks") }}
    </h6>

    <v-row>
      <v-col cols="1">
        <id-list
          :items="vertices"
          v-model="selectedRow"
          @input="onVertexSelect"
        ></id-list>
      </v-col>
      <v-col cols="11">
        <v-autocomplete
          v-if="selectedVertex"
          v-model="selectedVertex.name"
          outlined
          :items="taskNames"
          clearable
          :label="$t('global.concepts.name')"
          :rules="[notEmptyRule]"
          persistent-placeholder
          @change="onChange"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-btn depressed class="mt-2 button" fab rounded @click="onAdd">
      <v-icon>pic-add</v-icon>
    </v-btn>
    <v-btn
      depressed
      class="ml-10 mt-2 button"
      color="error"
      fab
      rounded
      v-if="canRemoveVertex()"
      @click="onRemove"
    >
      <v-icon>pic-delete</v-icon>
    </v-btn>
  </div>
</template>

<script>
import IdList from "../../IdList";
import { notEmpty } from "@/model/rules";

export default {
  name: "BpDefinitionVertexBuilder",
  components: {
    IdList,
  },
  props: {
    initVertices: [],
    tasks: [],
  },
  data() {
    return {
      vertices: [],
      selectedRow: -1,
      selectedVertex: undefined,
      taskNames: [],
      notEmptyRule: notEmpty(this),
    };
  },
  watch: {
    initVertices: function () {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.taskNames = this.tasks?.map((task) => task.name)?.sort();

      if (!this.initVertices || this.initVertices.length < 1) {
        this.onAdd();
      } else {
        this.vertices = this.initVertices;

        this.selectedRow = this.vertices.length - 1;
        this.selectedVertex = this.vertices[this.selectedRow];
      }
    },

    onAdd() {
      let numVertices = this.vertices.length;
      let id = numVertices < 1 ? 1 : this.vertices[numVertices - 1].id + 1;
      this.vertices.push(this.createVertex(id));
      this.onChange();
      this.selectedRow = this.vertices.length - 1;
      this.selectedVertex = this.vertices[this.selectedRow];
    },

    createVertex(id) {
      return {
        id: id,
        name: "",
        properties: {
          estimatedDuration: 1,
          showIcon: true,
        },
      };
    },
    canRemoveVertex() {
      return this.vertices.length > 1 && this.selectedRow != 0;
    },
    onVertexSelect() {
      if (this.selectedRow === -1) {
        return;
      }
      if (this.selectedRow > this.vertices.length) {
        throw new Error(`Invalid row selected: '${this.selectedRow}'`);
      }
      this.selectedVertex = this.vertices[this.selectedRow];
    },
    onRemove() {
      if (this.canRemoveVertex()) {
        this.vertices.splice(this.selectedRow, 1);
        this.onChange();
        this.selectedRow = this.selectedRow - 1;
        this.selectedVertex = this.vertices[this.selectedRow];
      }
    },
    onChange() {
      this.$emit("change", this.vertices);
    },
  },
  async created() {
    this.initialize();
  },
};
</script>
