<template>
  <create-edit-role isEdit :roleId="$route.params.roleId" cancelRoute="Role" />
</template>

<script>
import CreateEditRole from "@/components/security/CreateEditRole";

export default {
  name: "CreateRole",
  components: {
    CreateEditRole,
  },
};
</script>
