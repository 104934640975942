<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.administration.roles')"
    canCreate
    :createToolTip="
      $t('global.header.new', [$t('global.concepts.sysrole').toLowerCase()])
    "
    @newItem="create"
  >
    <roles-table v-if="roles" :roles="roles" />
  </module-template>
</template>

<script>
import { get } from "@/model/api";
import RolesTable from "@/components/security/RolesTable";
import ModuleTemplate from "@/components/layout/ModuleTemplate";

export default {
  name: "RoleList",
  components: {
    RolesTable,
    ModuleTemplate,
  },
  data() {
    return {
      roles: undefined,

      busy: true,
    };
  },
  methods: {
    async getRoles() {
      return await get("/role")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    create() {
      this.$router.push({ name: "Create role" });
    },
  },
  async created() {
    this.roles = await this.getRoles();
    this.busy = false;
  },
};
</script>
