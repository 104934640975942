<template>
  <module-template
    id="scrolltainer"
    title
    :canEdit="$store.state.fileEnvModifyPermission && item?.name !== 'default'"
    canList
    :canDelete="canDelete"
    @deleteItem="deleteFileEnv"
    @editItem="editFileEnv"
    @list="listEnvs"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.concepts.file-environment") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ fileEnv.name }}</div>
      </v-row>
    </template>

    <basic-card-horizontal :headers="headers" :item="item"
      ><template #[`item.label`]="{ item }">
        <file-env-chip
          :color="item.color"
          :label="item.label"
        ></file-env-chip> </template
    ></basic-card-horizontal>
  </module-template>
</template>

<script>
import { get, remove } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import FileEnvChip from "@/components/FileEnvChip";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";

export default {
  name: "FileEnv",
  components: {
    ModuleTemplate,
    FileEnvChip,
    BasicCardHorizontal,
  },

  computed: {
    canDelete() {
      return (
        this.$store.state.fileEnvDeletePermission && !!this.fileEnv?.deletable
      );
    },

    item() {
      const parent = this.fileEnv.parent === "" ? "-" : this.fileEnv.parent;
      return {
        parent: parent,
        label: this.fileEnv.label,
        color: this.fileEnv.color,
        vault: this.fileEnv.vault,
        numFiles: this.numFiles,
        numRuns: this.numRuns,
        numBps: this.numBpInstances,
      };
    },
  },
  data() {
    return {
      fileEnv: {},
      numFiles: 0,
      numRuns: 0,
      numBpInstances: 0,
      numOutsideRuns: 0,
      numOutsideBpInstances: 0,

      headers: [
        { text: this.$t("global.concepts.parent"), value: "parent" },
        { text: this.$t("global.environment.label"), value: "label" },
        { text: this.$t("global.administration.vault"), value: "vault" },
        { text: this.$t("global.environment.number-files"), value: "numFiles" },
        { text: this.$t("global.environment.number-runs"), value: "numRuns" },
        { text: this.$t("global.environment.number-bps"), value: "numBps" },
      ],
    };
  },
  methods: {
    listEnvs() {
      this.$router.push({ name: "File envs" });
    },

    async getFileEnv(id) {
      return await get("/file-env/" + id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async getDependenciesNumbers(id) {
      return await get("/file-env/" + id + "/dependencies-numbers")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async hasChildEnvs(id) {
      return await get("/file-env/" + id + "/has-child-env")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    async deleteFileEnv() {
      await remove("/file-env/" + this.fileEnv.id)
        .then(() => {
          this.$router.push({ name: "File envs" });
        })
        .catch((e) => {
          this.$store.commit(
            "showError",
            this.$t("global." + e.message, e.arguments)
          );
        });
    },

    editFileEnv() {
      this.$router.push({
        name: "Edit file env",
        params: { fileEnvId: this.fileEnv.id },
      });
    },
  },
  async created() {
    const fileEnvId = this.$route.params.fileEnvId;

    this.fileEnv = await this.getFileEnv(fileEnvId);

    const dependenciesNumbers = await this.getDependenciesNumbers(fileEnvId);

    this.numFiles = dependenciesNumbers?.numFiles;
    this.numRuns = dependenciesNumbers?.numRuns;
    this.numBpInstances = dependenciesNumbers?.numBpInstances;
    this.numOutsideRuns = dependenciesNumbers?.numOutsideRuns;
    this.numOutsideBpInstances = dependenciesNumbers?.numOutsideBpInstances;
  },
};
</script>
