<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="values.name"
          outlined
          :label="$t('global.concepts.name') + '*'"
          :disabled="isEdit || disabled"
          :rules="[notEmptyRule, noTagsRule]"
          persistent-placeholder
          @input="onInput()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="values.description"
          outlined
          :label="$t('global.concepts.description')"
          :disabled="disabled"
          persistent-placeholder
          :rules="[noTagsRule]"
          @input="onInput()"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <basic-switch
          v-model="showCustomForm"
          dense
          :label="showFormText"
          hide-details
        />
      </v-col>
    </v-row>

    <v-row v-if="showCustomForm">
      <v-col cols="6">
        <v-textarea
          v-model="values.form"
          outlined
          :label="$t('global.concepts.json-form')"
          :disabled="disabled"
          auto-grow
          :rules="[noTagsRule]"
          persistent-placeholder
          @input="onInput()"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <bp-definition-builder
          v-if="!disabled"
          :initJsonGraph="initJsonGraph"
          @change="onChange"
        ></bp-definition-builder>
      </v-col>
    </v-row>

    <v-btn
      class="mt-2"
      v-if="!disabled && valid"
      @click="generateImage"
      color="primary"
      >{{ showGraphText }}</v-btn
    >
    <img v-if="imageLoaded && !disabled && valid" :src="imageSrc" alt="graph" />
  </v-container>
</template>

<script>
import { post } from "@/model/api";
import BpDefinitionBuilder from "./BpDefinitionBuilder.vue";
import { createInvalidGraphError } from "@/model/util";
import BasicSwitch from "@/components/BasicSwitch";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "BpDefinitionFormFields",
  components: {
    BpDefinitionBuilder,
    BasicSwitch,
  },
  props: {
    disabled: Boolean,
    initValues: Object,
    isEdit: Boolean,
    valid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      values: {},
      initJsonGraph: "",
      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
      showCustomForm: false,

      imageSrc: "",
      imageLoaded: false,
    };
  },
  watch: {
    initValues: function () {
      this.initialize();
    },
  },

  computed: {
    showGraphText() {
      return this.imageLoaded
        ? this.$t("global.business-process.hide-image")
        : this.$t("global.business-process.show-image");
    },

    showFormText() {
      if (this.disabled) {
        return this.$t("global.business-process.show-form");
      }
      if (this.isEdit) {
        return this.$t("global.business-process.edit-form");
      }
      return this.$t("global.business-process.custom-form");
    },
  },

  methods: {
    initialize() {
      this.values = this.initValues;
      this.initJsonGraph = this.initValues.jsonGraph;
    },

    onInput() {
      this.$emit("input", this.values);
    },

    onChange(graph) {
      this.values.jsonGraph = graph;
      this.onInput();
    },

    async getImage(graph) {
      const data = { jsonGraph: graph };
      return await post("/business-process/image", data).catch((e) => {
        this.$store.commit("showError", createInvalidGraphError(e));
      });
    },

    async generateImage() {
      if (this.imageLoaded) {
        this.imageLoaded = false;
      } else {
        const image = await this.getImage(this.values.jsonGraph);
        if (image) {
          this.imageSrc = "data:image/png;base64," + image;
        }
        this.imageLoaded = true;
      }
    },
  },

  created() {
    this.initialize();
  },
};
</script>

<style>
img {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}
</style>
