<template>
  <module-template :title="$t('global.info.info')">
    <v-tabs class="ooliba-tab" active-class="ooliba-tab-active" v-model="tab">
      <v-tab v-for="container in containers" :key="container">
        {{ container }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="container in containers" :key="container">
        <v-card
          ><v-card-title>{{ $t("global.info.system-info") }}</v-card-title>
          <v-card-text>
            <basic-card-horizontal
              :headers="systemInfoHeaders[container]"
              :item="systemInfoItem[container]"
              ><template #[`item.threadDump`]>
                <a color="blue" :href="threadDumpUrl(container)" download>{{
                  $t("global.action.download")
                }}</a></template
              ></basic-card-horizontal
            ></v-card-text
          >
        </v-card>
        <v-card class="mt-4" v-if="dbItem[container]"
          ><v-card-title>{{ $t("global.info.database-settings") }}</v-card-title
          ><v-card-text>
            <basic-card-horizontal
              :headers="dbHeaders[container]"
              :item="dbItem[container]"
            ></basic-card-horizontal></v-card-text
        ></v-card>
        <v-card class="mt-4"
          ><v-card-title>{{
            $t("global.info.environment-properties")
          }}</v-card-title
          ><v-card-text
            ><basic-card-horizontal
              :headers="envPropHeaders[container]"
              :item="envPropItem[container]"
            ></basic-card-horizontal></v-card-text
        ></v-card>
        <v-card class="mt-4"
          ><v-card-title>{{ $t("global.info.java-properties") }}</v-card-title
          ><v-card-text
            ><basic-card-horizontal
              :headers="javaPropHeaders[container]"
              :item="javaPropItem[container]"
            ></basic-card-horizontal></v-card-text
        ></v-card>
      </v-tab-item>
    </v-tabs-items>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";
import { baseUrl } from "@/model/http-common";
import { get } from "@/model/api";

export default {
  name: "SystemInfo",

  components: {
    ModuleTemplate,
    BasicCardHorizontal,
  },

  data() {
    return {
      systemInfoHeaders: {},

      systemInfoItem: {},

      dbHeaders: {},
      dbItem: {},

      envPropHeaders: {},
      envPropItem: {},

      javaPropHeaders: {},
      javaPropItem: {},

      tab: null,
      containers: [],

      maxValueLength: 100,
    };
  },

  computed: {},

  methods: {
    threadDumpUrl(container) {
      return `${baseUrl}/info/thread-dump?container=${container}`;
    },
  },

  async created() {
    const infoMap = await get("/info").catch((e) => {
      this.$store.commit("showError", e);
    });
    if (!infoMap) return;

    this.containers = Object.keys(infoMap);

    for (const container of this.containers) {
      const info = infoMap[container];

      let systemInfoHeaders = [
        {
          text: this.$t("global.info.thread-dump"),
          value: "threadDump",
          class: "ooliba-font-color-blue",
        },

        {
          text: this.$t("global.info.server-version"),
          value: "serverVersion",
        },

        {
          text: this.$t("global.info.server-build-time"),
          value: "serverBuildTime",
        },

        {
          text: this.$t("global.info.engine-version"),
          value: "engineVersion",
        },

        {
          text: this.$t("global.info.host-name"),
          value: "hostName",
        },
      ];

      let systemInfoItem = {
        serverVersion: info.serverVersion,
        serverBuildTime: info.serverBuildTime,
        engineVersion: info.engineVersion,
        hostName: info.hostName,
      };

      if (info.networkInterfaces) {
        Object.entries(info.networkInterfaces).forEach(([key, value]) => {
          systemInfoHeaders.push({
            text: "- " + key,
            value: key,
          });

          systemInfoItem[key] = value.join(", ");
        });
      }

      systemInfoHeaders.push(
        {
          text: this.$t("global.info.java-version"),
          value: "javaVersion",
        },
        {
          text: this.$t("global.info.max-heap-space"),
          value: "maxHeapSpace",
        },
        {
          text: this.$t("global.info.total-heap-space"),
          value: "totalHeapSpace",
        },
        {
          text: this.$t("global.info.number-active-threads"),
          value: "numberActiveThreads",
        },
        {
          text: this.$t("global.info.physical-memory-size"),
          value: "physicalMemorySize",
        },
        {
          text: this.$t("global.info.free-physical-memory-size"),
          value: "freePhysicalMemorySize",
        }
      );
      systemInfoItem.javaVersion = info.javaVersion;
      systemInfoItem.maxHeapSpace =
        info.maxHeapSpace + " " + this.$t("global.info.mb");
      systemInfoItem.totalHeapSpace =
        info.totalHeapSpace + " " + this.$t("global.info.mb");
      systemInfoItem.numberActiveThreads = info.numThreads;
      systemInfoItem.physicalMemorySize =
        info.memory + " " + this.$t("global.info.mb");
      systemInfoItem.freePhysicalMemorySize =
        info.freeMemory + " " + this.$t("global.info.mb");

      if (info.memoryPools) {
        Object.entries(info.memoryPools).forEach(([key, value]) => {
          systemInfoHeaders.push({
            text: "- " + key,
            value: key,
          });

          systemInfoItem[key] = value;
        });
      }

      systemInfoHeaders.push({
        text: this.$t("global.info.number-available-processors"),
        value: "numberAvailableProcessors",
      });
      systemInfoItem.numberAvailableProcessors = info.availableProcessors;

      this.systemInfoHeaders[container] = systemInfoHeaders;
      this.systemInfoItem[container] = systemInfoItem;

      if (info.dbUrl && info.dbUser) {
        const dbHeaders = [
          {
            text: this.$t("global.info.url"),
            value: "url",
          },
          {
            text: this.$t("global.info.user"),
            value: "user",
          },
        ];
        this.dbHeaders[container] = dbHeaders;

        const dbItem = {
          url: info.dbUrl,
          user: info.dbUser,
        };

        this.dbItem[container] = dbItem;
      }

      let envPropHeaders = [];
      let envPropItem = {};

      Object.entries(info.envProps).forEach(([key, value]) => {
        envPropHeaders.push({
          text: key,
          value: key,
        });

        var croppedValue = value;
        if (croppedValue.length > this.maxValueLength) {
          croppedValue = croppedValue.substring(0, this.maxValueLength) + "...";
        }
        envPropItem[key] = croppedValue;
      });

      this.envPropHeaders[container] = envPropHeaders;
      this.envPropItem[container] = envPropItem;

      let javaPropHeaders = [];
      let javaPropItem = {};

      Object.entries(info.javaProps).forEach(([key, value]) => {
        javaPropHeaders.push({
          text: key,
          value: key,
        });

        var croppedValue = value;
        if (croppedValue.length > this.maxValueLength) {
          croppedValue = croppedValue.substring(0, this.maxValueLength) + "...";
        }
        javaPropItem[key] = croppedValue;
      });

      this.javaPropHeaders[container] = javaPropHeaders;
      this.javaPropItem[container] = javaPropItem;
    }
  },
};
</script>
