<template>
  <div id="the-home"></div>
</template>

<script>
export default {
  name: "AppHome",

  created() {
    if (this.$store.state.bpInstReadPermission) {
      this.$router.push({ name: "BP instances" });
    } else if (this.$store.state.runReadPermission) {
      this.$router.push({ name: "Runs" });
    } else {
      this.$router.push({ name: "Modules" });
    }
  },
};
</script>
