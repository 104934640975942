<template>
  <v-menu
    v-model="open"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    :disabled="disabled"
    :max-height="maxHeight"
    :max-width="maxWidth"
    offset-y
  >
    <template #activator="{ on }">
      <div v-on="on">
        <slot>
          <v-btn
            :color="color"
            :dark="btnDark"
            :fab="btnFab"
            :rounded="btnRounded"
            :small="btnSmall"
          >
            {{ btnLabel }}
            <v-icon v-if="btnIcon" :right="iconRight"> {{ btnIcon }}</v-icon>
          </v-btn>
        </slot>
      </div>
    </template>

    <v-card>
      <v-card-text class="body-1 font-weight-medium">
        {{ `${text}` }}
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-spacer></v-spacer>
        <!-- No -->
        <v-btn depressed @click="onNo">
          <v-icon left>mdi-close</v-icon>
          {{ textNo }}
        </v-btn>
        <!-- Yes -->
        <v-btn id="btn-yes" color="primary" depressed @click="onYes">
          <v-icon left>mdi-check</v-icon>
          {{ textYes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnClick: Boolean,
    closeOnContentClick: Boolean,

    color: String,

    btnFab: Boolean,
    btnIcon: String,
    btnLabel: String,
    btnDark: Boolean,
    btnRounded: Boolean,
    btnSmall: Boolean,

    iconRight: Boolean,

    disabled: Boolean,

    maxHeight: {
      type: String,
      default: "240",
    },
    maxWidth: {
      type: String,
      default: "450",
    },

    text: String,
    textNo: {
      type: String,
      default: "No",
    },
    textYes: {
      type: String,
      default: "Yes",
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    onNo() {
      this.open = false;
      this.$emit("no");
    },
    onYes() {
      this.open = false;
      this.$emit("yes");
    },
  },
};
</script>

<style></style>
