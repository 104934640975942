<template>
  <create-edit-file-env :cancelRoute="'File envs'" />
</template>

<script>
import CreateEditFileEnv from "@/components/CreateEditFileEnv";

export default {
  name: "CreateFileEnv",
  components: {
    CreateEditFileEnv,
  },
};
</script>
