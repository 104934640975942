<template>
  <ooliba-basic-table
    :headers="headers"
    :items="items"
    :loading="busy"
    no-pagination
    @row-clicked="onRowClick"
  />
</template>

<script>
import OolibaBasicTable from "@/components/OolibaBasicTable";

export default {
  name: "RolesOolibaBasicTable",
  components: { OolibaBasicTable },
  props: {
    roles: Array,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.description"), value: "description" },
      ],
      items: [],

      busy: true,
    };
  },
  methods: {
    onRowClick(record) {
      const roleId = record.id;
      this.$router.push({ name: "Role", params: { roleId: roleId } });
    },
  },
  async created() {
    this.roles.forEach((value) => {
      const item = {};

      item["id"] = value.id;
      item[this.headers[0].value] = value.name;
      item[this.headers[1].value] = value.description;

      this.items.push(item);
    });
    this.busy = false;
  },
};
</script>
