<template>
  <div class="text-center">
    <v-icon small v-if="value" color="green"> pic-check </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>
