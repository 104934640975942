<template>
  <span>
    <v-tooltip v-if="!!text" bottom>
      <template #activator="{ on }">
        <span v-on="on">
          <slot>
            <v-icon :color="color"> {{ icon }} </v-icon>
          </slot>
        </span>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
    <slot v-else>
      <v-icon :color="color"> {{ icon }} </v-icon>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    color: String,
    icon: String,
    text: String,
  },
};
</script>
