<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot>
            <v-btn
              style="height: 22px"
              :color="color"
              :dark="dark"
              :small="small"
              :outlined="outlined"
            >
              {{ text }}
              <v-icon small> mdi-menu-down </v-icon>
            </v-btn>
          </slot>
        </div>
      </template>
      <v-list class="btn-menu-list pa-0" dense nav>
        <template v-for="(item, index) in items">
          <v-list-item
            v-if="!item.hide"
            class="mb-0"
            :disabled="item.disabled || disabledActions[item.value]"
            :key="index"
            @click="onClick(item.value, $event)"
          >
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>

            <v-list-item-icon v-if="item.icon">
              <v-icon small> {{ item.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider v-if="item.divider" :key="`divider-${index}`"> </v-divider>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    dark: Boolean,

    /**
     * To disable a specific action => disabledAction[value] = true
     */
    disabledActions: {
      type: Object,
      default: () => {
        return {};
      },
    },

    /**
     * Array of [{
     * value:'eventOnClick',
     * text: 'Button label',
     * divider: true, // add a divider bellow item
     * disabled: // disable the item
     * }]
     */
    items: {
      type: Array,
      default: () => [],
    },
    small: Boolean,
    text: String,
    outlined: Boolean,
  },

  data() {
    return {
      selected: undefined,
    };
  },

  methods: {
    onClick(emit, clickEvent) {
      this.$emit(emit, clickEvent);
    },
  },
};
</script>
