<template>
  <module-template
    id="scrolltainer"
    title
    canDelete
    canList
    @list="listVaults"
    @deleteItem="deleteVault"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.concepts.sysvault") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ vault.name }}</div>
      </v-row>
    </template>

    <v-card>
      <v-card-text>
        <basic-card-horizontal
          :headers="[
            { text: $t('global.administration.owner'), value: 'owner' },
          ]"
          :item="{ owner: owner }"
          :max-width="400"
        ></basic-card-horizontal>

        <h6 class="py-4">
          {{ $t("global.administration.access-list") }}
        </h6>

        <ooliba-basic-table
          :headers="headers"
          :items="items"
          :loading="busy"
          no-pagination
          numberItemShow="100"
          @row-clicked="onRowClick"
        >
          <template #[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="editUser(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  pic-edit
                </v-icon>
              </template>
              <span>{{ $t("global.action.edit") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="red"
                  @click.stop="deleteUser(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  pic-delete
                </v-icon>
              </template>
              <span>{{ $t("global.tooltip.effisoftToolbarDelete") }}</span>
            </v-tooltip>
          </template>

          <template #[`item.readPermission`]="{ item }">
            <v-icon v-if="item.readPermission" small color="green">
              pic-check
            </v-icon>
            <div v-else></div>
          </template>

          <template #[`item.writePermission`]="{ item }">
            <v-icon v-if="item.writePermission" small color="green">
              pic-check
            </v-icon>
            <div v-else></div>
          </template>
        </ooliba-basic-table>

        <v-btn v-on:click="addUser" class="mt-3" color="primary">{{
          $t("global.administration.add-user")
        }}</v-btn>
        <v-dialog v-model="showAddEdit" width="500">
          <add-edit-vault-user
            v-if="showAddEdit"
            :vaultId="this.vault.id"
            @done="onAddEditDone"
            @cancel="onAddEditCancel"
            :isEdit="isEdit"
            :initUser="editingUser"
            :initWritePermission="editingWritePermission"
          />
        </v-dialog>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { get, remove } from "@/model/api";
import AddEditVaultUser from "@/components/security/AddEditVaultUser";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";

export default {
  name: "SecVault",
  components: {
    AddEditVaultUser,
    ModuleTemplate,
    OolibaBasicTable,
    BasicCardHorizontal,
  },
  data() {
    return {
      vault: {},
      owner: "",
      accessList: [],
      headers: [
        { text: this.$t("global.concepts.sysuser"), value: "user" },
        {
          text: this.$t("global.administration.read-permission"),
          value: "readPermission",
        },
        {
          text: this.$t("global.administration.write-permission"),
          value: "writePermission",
        },
        {
          text: this.$t("global.concepts.actions"),
          value: "action",
          sortable: false,
        },
      ],
      items: [],
      isEdit: false,
      editingUser: "",
      editingWritePermission: false,
      showAddEdit: false,

      busy: true,
    };
  },

  methods: {
    listVaults() {
      this.$router.push({ name: "Vaults" });
    },
    async getVault(id) {
      return await get("/vault/" + id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async getOwner(id) {
      return await get("/vault/" + id + "/owner")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    async getAccessList(id) {
      return await get("/vault/" + id + "/users")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    addUser() {
      this.isEdit = false;
      this.editingUser = "";
      this.editingWritePermission = false;
      this.showAddEdit = true;
    },

    async onAddEditDone() {
      this.showAddEdit = false;

      await this.updateAccessListTable(this.vault.id);
    },

    async onAddEditCancel() {
      this.showAddEdit = false;
    },

    editUser(row) {
      this.isEdit = true;
      this.editingUser = row.user;
      this.editingWritePermission = row.writePermission;
      this.showAddEdit = true;
    },
    async deleteUser(row) {
      await remove("/vault/" + this.vault.id + "/user/" + row.user)
        .then(() => {
          this.updateAccessListTable(this.vault.id);
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async deleteVault() {
      await remove("/vault/" + this.vault.id)
        .then(() => {
          this.$router.push({ name: "Vaults" });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    onRowClick(row) {
      const id = row.id;

      this.$router.push({
        name: "User",
        params: { userId: id },
      });
    },
    async updateAccessListTable(vaultId) {
      this.busy = true;
      this.items = [];
      this.accessList = await this.getAccessList(vaultId);

      if (this.accessList) {
        this.accessList.forEach((value) => {
          const item = {};

          item["id"] = value.id;
          item[this.headers[0].value] = value.name;
          item[this.headers[1].value] = value.readPermission;
          item[this.headers[2].value] = value.writePermission;

          this.items.push(item);
        });
      }
      this.busy = false;
    },
  },
  async created() {
    const vaultId = this.$route.params.vaultId;

    this.vault = await this.getVault(vaultId);
    this.owner = await this.getOwner(vaultId);
    await this.updateAccessListTable(vaultId);
    this.busy = false;
  },
};
</script>
