<template>
  <v-text-field
    ref="effisoftText"
    :id="id"
    :value="value"
    :class="{
      'border-readonly': borderReadonly,
      'effisoft-required': required,
    }"
    :required="required"
    :append-icon="appendIcon"
    :autocomplete="autocomplete"
    :clearable="clearable && value !== null && value !== ''"
    :clear-icon="clearIcon"
    :counter="counter"
    :disabled="disabled"
    :dense="denseLocal"
    :hide-details="hideDetails"
    :label="label"
    :loading="loading"
    :maxlength="maxlength"
    :outlined="outlined"
    :placeholder="placeholder"
    :prefix="prefix"
    :readonly="readonly"
    :rules="allRules"
    :suffix="suffix"
    :type="type"
    persistent-placeholder
    @blur="onBlur"
    @change="$emit('change', $event)"
    @click="$emit('click', $event)"
    @click:append="$emit('click:append', $event)"
    @focus="$emit('focus', $event)"
    @input="onInput"
    @keypress="onKeyPress"
    @keyup="onKeyUp"
    @paste="onPaste"
  />
</template>

<script>
import { noTags } from "@/model/rules";

/**
 * Effisoft text field.
 */
export default {
  name: "compliance-text",
  props: {
    /**
     * Allow to insert 0
     */
    allowZero: {
      type: Boolean,
      default: false,
    },

    /**
     * append icon
     */
    appendIcon: {
      type: String,
    },

    /**
     * default autocomplete input
     */
    autocomplete: {
      type: String,
      default: "off",
    },

    borderReadonly: Boolean,

    /**
     * To display a length number at the bottom of the input line
     */
    counter: {
      Type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    clearIcon: {
      type: String,
      default: null,
    },
    /**
     * To disabled
     */
    disabled: {
      type: Boolean,
    },

    disabledEvents: Boolean,

    /**
     * To hide error message and remove the bottom space allocated
     */
    hideDetails: {
      type: Boolean,
    },

    /**
     * HTML id
     */
    id: {
      type: String,
    },

    /**
     * label
     */
    label: {
      type: String,
    },

    loading: {
      type: Boolean,
    },

    /**
     * Apply a custom mask.
     * # = any number.
     * Example: ##-## = 12-34
     */
    mask: {
      type: [String],
    },

    /**
     * limit the input character length
     */
    maxlength: {
      type: [Number, String],
    },

    /**
     * v-text-field outlined prop
     */
    outlined: {
      type: Boolean,
      default: true,
    },

    /**
     * To put an text when the field is empty.
     */
    placeholder: {
      type: String,
      default: " ",
    },

    /**
     * text prefix
     */
    prefix: {
      type: String,
    },

    /**
     * When true the value the mandatory rule is added.
     */
    required: {
      type: Boolean,
      default: false,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Add vuetify rules
     */
    rules: {
      type: Array,
      default() {
        return [];
      },
    },

    /**
     * Bind to a variable if the rules depends on the variable.
     * This will relaunch the rules when the variable depend on changed
     */
    rulesDependOn: {
      type: [Object, String, Number, Array],
    },

    /**
     * Add a suffix on the text
     */
    suffix: {
      type: String,
    },
    // Removes spaces at value start and end
    trimmed: {
      type: Boolean,
      default: false,
    },
    /**
     * Set vuetify v-text-field.type
     */
    type: {
      type: String,
      default: "text",
    },

    /**
     * Text field value
     */
    value: {
      type: [Number, String, Array],
    },
  },

  data() {
    return {
      changeValue: false,
      denseGrid: false,
      keyUpFormatEventInputHtml: null,
      keyUpFormatEventIsEnabled: false,
      keyUpFormatEventStartCursor: 0,
    };
  },

  computed: {
    allRules() {
      if (this.disabled) {
        return [];
      }
      let allRules = [...this.rules];
      allRules = allRules.concat(noTags(this));

      if (this.required && !this.disabled) {
        allRules = allRules.concat(this.ruleRequired);
      }

      return allRules;
    },

    denseLocal() {
      return this.denseGrid || this.dense;
    },
  },

  watch: {
    rulesDependOn: {
      deep: true,
      handler(value) {
        if (this.$refs.effisoftText && value) {
          this.$refs.effisoftText.validate();
          if (this.$refs.effisoftText.errorBucket.length) {
            this.$refs.effisoftText.hasInput = true;
          }
        }
      },
    },

    async value(value) {
      // format only if there is a mask and if it is the fisrt time loading
      if (this.mask && !this.keyUpFormatEventIsEnabled && this.value) {
        let valueFormated = this.formatValue(value, this.mask);
        if (valueFormated !== value) {
          // save the cursor position
          let cursorStart = this.$refs.effisoftText.$refs.input.selectionStart;
          let cursorEnd = this.$refs.effisoftText.$refs.input.selectionEnd;

          await this.onInput(valueFormated);

          // replace the cursor position after restart
          this.$refs.effisoftText.$refs.input.selectionStart = cursorStart;
          this.$refs.effisoftText.$refs.input.selectionEnd = cursorEnd;
        }
      }
    },
  },

  mounted() {
    this.isDense();
  },

  methods: {
    blur() {
      this.$refs.effisoftText.blur();
    },

    checkMask(value, mask) {
      for (let i = 0; i < value.length; i++) {
        let currMask = mask[i];
        let currVal = value[i];

        let regex = this.maskCharToRegex(currMask);
        let isInvalide = !regex.test(currVal);

        if (isInvalide) {
          return false;
        }
      }
      return true;
    },

    enabledKeyUpFormatEvent(startCursor, inputHtml) {
      this.keyUpFormatEventStartCursor = startCursor;
      this.keyUpFormatEventInputHtml = inputHtml;
      this.keyUpFormatEventIsEnabled = true;
    },

    focus() {
      this.$refs.effisoftText.focus();
    },

    formatValue(value, mask) {
      let i = 0;
      while (i < value.length) {
        let currMask = mask[i];
        let currVal = value[i];

        let regex = this.maskCharToRegex(currMask);

        if (!regex.test(currVal)) {
          if (currMask !== "#") {
            // the mask need a char but find a number
            value = value.slice(0, i) + currMask + value.slice(i);
            i = i + 2;
          } else {
            // the mask need a number but find a char
            value = value.slice(0, i) + value.slice(i + 1);
          }
        } else {
          i++;
        }
      }
      return value;
    },

    getPasteResult(startCursor, endCursor, value, pasteValue) {
      let completeValue =
        value.slice(0, startCursor) + pasteValue + value.slice(startCursor);
      if (startCursor < endCursor) {
        completeValue =
          value.substring(0, startCursor) +
          pasteValue +
          value.substring(endCursor, value.length);
      }
      return completeValue;
    },

    isDense() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options.name === "v-data-table") {
          this.denseGrid = parent.dense;
          break;
        }
        parent = parent.$parent;
      }
    },

    async onInput(value) {
      this.$emit("input", value);
    },

    maskCharToRegex(char) {
      let regex = "";
      switch (char) {
        case "#":
          regex = "[0-9]";
          break;

        case "/":
          regex = "\\/";
          break;

        default:
          regex = char;
      }
      return new RegExp(regex);
    },

    async onBlur(evt) {
      if (this.trimmed) {
        let valueTrim =
          !!this.value && this.value.length ? this.value.trim() : this.value;
        await this.$emit("input", valueTrim);
      }
      await this.$emit("blur", evt);
    },

    onPaste(evt) {
      if (this.mask) {
        const pasteValue = evt.clipboardData.getData("text");
        const value = evt.target.value;
        const startCursor = evt.target.selectionStart;
        const endCursor = evt.target.selectionEnd;

        // get the resultValue
        let resultValue = this.getPasteResult(
          startCursor,
          endCursor,
          value,
          pasteValue
        );
        const lengthIsInvalid = resultValue.length > this.mask.length;
        if (lengthIsInvalid) {
          evt.preventDefault();
        }

        // reformat and check
        resultValue = this.formatValue(resultValue, this.mask);
        if (resultValue.length === 0) {
          evt.preventDefault();
        }

        // check validity with mask
        const valueIsInvalid = !this.checkMask(resultValue, this.mask);
        if (valueIsInvalid) {
          evt.preventDefault();
        }

        this.enabledKeyUpFormatEvent(this.mask.length - 1, evt.target);
      }

      this.$emit("paste", evt);
    },

    onKeyPress(evt) {
      if (this.mask) {
        const startCursor = evt.target.selectionStart;
        const endCursor = evt.target.selectionEnd;
        let valuePress = evt.key;
        let completeValue = valuePress;
        if (this.value) {
          completeValue = this.getPasteResult(
            startCursor,
            endCursor,
            this.value,
            valuePress
          );
        }

        let lengthIsInvalid = completeValue.length > this.mask.length;
        if (lengthIsInvalid) {
          evt.preventDefault();
          return;
        }

        const currentMask = this.mask[startCursor];
        let regex = this.maskCharToRegex(currentMask);
        let keyPressIsInvalid = regex && !regex.test(valuePress);
        if (keyPressIsInvalid) {
          evt.preventDefault();
          return;
        }

        // see next mask
        if (startCursor + 1 < this.mask.length) {
          this.enabledKeyUpFormatEvent(startCursor + 1, evt.target);
        }
      }

      // empêche la saisie d'espaces au début et à la fin du texte
      /*
      if (this.trimmed) {
        const startCursor = evt.target.selectionStart
        const endCursor = evt.target.selectionEnd
        const valueAfter = this.value
          ? this.getPasteResult(startCursor, endCursor, this.value, evt.key)
          : evt.key

        if (valueAfter !== valueAfter.trim()) {
          evt.preventDefault()
          return
        }
      }
      */
      this.$emit("keypress", evt);
    },

    async onKeyUp(evt) {
      if (this.keyUpFormatEventIsEnabled) {
        let newVal = this.value;
        let offsetCursor = 0;
        const needToAddOneChar =
          this.mask[this.keyUpFormatEventStartCursor] &&
          this.mask[this.keyUpFormatEventStartCursor] !== "#";
        if (needToAddOneChar) {
          newVal =
            this.value.slice(0, this.keyUpFormatEventStartCursor) +
            this.mask[this.keyUpFormatEventStartCursor] +
            this.value.slice(this.keyUpFormatEventStartCursor);
          offsetCursor = 1;
        }

        // format and correct the value
        newVal = this.formatValue(newVal, this.mask);

        // await the event then set the cursor
        await this.onInput(newVal);

        // disabled the event
        this.keyUpFormatEventIsEnabled = false;
        this.keyUpFormatEventInputHtml.selectionStart =
          this.keyUpFormatEventStartCursor + offsetCursor;
        this.keyUpFormatEventInputHtml.selectionEnd =
          this.keyUpFormatEventInputHtml.selectionStart;
      }
      this.$emit("keyup", evt);
    },

    reset() {
      this.$refs.effisoftText.reset();
    },

    ruleRequired(v) {
      if (this.allowZero) {
        return (
          !!v || parseFloat(v) === 0 || this.$i18n.t("global.rules.mandatory")
        );
      } else {
        return !!v || this.$i18n.t("global.rules.mandatory");
      }
    },
  },
};
</script>
