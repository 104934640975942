// import i18n from "@/i18n";

export const noTags = (vue) => {
  return (v) => {
    if (!v || !isNaN(Number(v))) {
      return true;
    }

    const beginTagIndex = v.indexOf("<");
    const endTagIndex = v.indexOf(">", beginTagIndex);
    const hasTag =
      beginTagIndex >= 0 && endTagIndex >= 0 && beginTagIndex < endTagIndex;
    return !hasTag || vue.$t("global.rules.sanitize");
  };
};

export const notEmpty = (vue, disabled) => {
  if (disabled) {
    return () => true;
  }
  return (v) => !!v || vue.$t("global.rules.mandatory");
};

export const emailValid = (vue) => {
  return (v) => /.+@.+/.test(v) || vue.$t("global.rules.email-valid");
};
