<i18n>
{
  "en" :
  {
    "local" :
    {
      "actions":
      {
        "contact": "Contact",
        "submit": "Submit"
      },
      "fields":
      {
        "schema": "Schema",
        "password": "Password",
        "welcome": "Welcome"
      },
      "msg":
      {
        "account_expired": "The end date of access of this user to the application is over.",
        "account_locked": "This account is locked.",
        "error-setting-account": "Error setting on user account",
        "link": "link to Duck Creek Technologies website",
        "login_invalid": "Wrong username or password.",
        "technical_error": "An internal error occured."
      },
      "tooltips": {
        "logo": "Duck Creek Technologies' logo"
      }
    }
  },
  "fr" :
  {
    "local" :
    {
      "actions":
      {
        "contact": "Contact",
        "submit": "Valider"
      },
      "fields":
      {
        "schema": "Schéma",
        "password": "Mot de passe",
        "welcome": "Bienvenue"
      },
      "msg":
      {
        "account_expired": "La date de fin d'accès à l'application, de cet utilisateur, est dépassée.",
        "account_locked": "Compte bloqué.",
        "error-setting-account": "Erreur de paramétrage sur le compte utilisateur",
        "login_invalid": "Nom d'utilisateur ou mot de passe incorrect.",
        "link": "lien vers le site de Duck Creek Technologies",
        "technical_error": "Une erreur interne s'est produite."
      },
      "tooltips": {
        "logo": "Logo de Duck Creek Technologies"
      }
    }
  }
}
</i18n>

<template>
  <div class="ui-sign-in compliance">
    <div class="left">
      <h1>{{ $t("local.fields.welcome") }}</h1>
      <v-form @submit.prevent="login">
        <compliance-text
          id="formLoginUsername"
          v-model.trim="username"
          :disabled="isLogging"
          :label="$t('global.concepts.sysuser')"
          :outlined="false"
          required
        />

        <compliance-password
          id="formLoginPassword"
          v-model.trim="password"
          :disabled="isLogging"
          :label="$t('local.fields.password')"
          :outlined="false"
          :rules="[]"
          required
        />

        <v-container>
          <v-layout column>
            <v-flex class="mt-5" text-center>
              <v-btn
                id="button"
                ref="btnLogIn"
                :loading="isLogging"
                color="primary"
                depressed
                type="submit"
              >
                <v-icon left>pic-key</v-icon>
                {{ $t("local.actions.submit") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <a
        href="https://www.duckcreek.com/"
        :title="$t('local.msg.link')"
        class="logo-prima"
      >
        <img
          :alt="$t('local.tooltips.logo')"
          src="@/assets/duck-dct-color.svg"
          width="245"
          height="58"
        />
      </a>
    </div>
    <div class="right">
      <div class="logo">
        <h1>Duck creek <strong>Compliance</strong></h1>
      </div>
      <h2>{{ $t("global.header.title") }}</h2>
    </div>
  </div>
</template>

<script>
import { post } from "@/model/api";
import ComplianceText from "@/components/ComplianceText";
import CompliancePassword from "@/components/CompliancePassword";

export default {
  name: "UserLogin",
  components: {
    ComplianceText,
    CompliancePassword,
  },
  data() {
    return {
      show: false,
      username: "",
      password: "",

      isLogging: false,
    };
  },
  methods: {
    async login() {
      this.isLogging = true;

      const loginData = {
        username: this.username,
        password: this.password,
        rememberMe: false,
      };

      await post("/authenticate", loginData)
        .then((response) => {
          this.commitLoginDetailsToStore(response);
        })
        .catch((error) => {
          this.isLogging = false;

          switch (error.status) {
            case 401:
              this.$store.commit(
                "showError",
                this.$t("local.msg.login_invalid")
              );
              break;
            default:
              this.$store.commit(
                "showError",
                this.$t("local.msg.technical_error")
              );
          }
        });
    },

    commitLoginDetailsToStore(response) {
      localStorage.username = this.username;
      this.$store.commit("setUsername", localStorage.username);

      localStorage.locale = response.locale;
      this.$store.commit("setLocale", response.locale);
      this.$root.$i18n.locale = response.locale;

      const permissions = [];

      response.permissions.forEach((element) => {
        permissions.push(element.authority);
      });

      localStorage.runReadPermission = permissions.includes("ROLE_RUN_READ");
      this.$store.commit(
        "setRunReadPermission",
        localStorage.runReadPermission === "true"
      );

      localStorage.runCreatePermission =
        permissions.includes("ROLE_RUN_CREATION");
      this.$store.commit(
        "setRunCreatePermission",
        localStorage.runCreatePermission === "true"
      );

      localStorage.runDeletePermission =
        permissions.includes("ROLE_RUN_DELETION");
      this.$store.commit(
        "setRunDeletePermission",
        localStorage.runDeletePermission === "true"
      );

      localStorage.runRenamePermission =
        permissions.includes("ROLE_RUN_RENAME");
      this.$store.commit(
        "setRunRenamePermission",
        localStorage.runRenamePermission === "true"
      );

      localStorage.bpInstReadPermission = permissions.includes(
        "ROLE_BP_INSTANCE_READ"
      );
      this.$store.commit(
        "setBpInstReadPermission",
        localStorage.bpInstReadPermission === "true"
      );

      localStorage.bpInstCreatePermission = permissions.includes(
        "ROLE_BP_INSTANCE_CREATION"
      );
      this.$store.commit(
        "setBpInstCreatePermission",
        localStorage.bpInstCreatePermission === "true"
      );

      localStorage.bpInstDeletePermission = permissions.includes(
        "ROLE_BP_INSTANCE_DELETION"
      );
      this.$store.commit(
        "setBpInstDeletePermission",
        localStorage.bpInstDeletePermission === "true"
      );

      localStorage.bpInstModifyPermission = permissions.includes(
        "ROLE_BP_INSTANCE_MODIFY"
      );
      this.$store.commit(
        "setBpInstModifyPermission",
        localStorage.bpInstModifyPermission === "true"
      );

      localStorage.bpInstRenamePermission = permissions.includes(
        "ROLE_BP_INSTANCE_RENAME"
      );
      this.$store.commit(
        "setBpInstRenamePermission",
        localStorage.bpInstRenamePermission === "true"
      );

      localStorage.bpInstLockPermission = permissions.includes(
        "ROLE_BP_INSTANCE_LOCK_UNLOCK"
      );
      this.$store.commit(
        "setBpInstLockPermission",
        localStorage.bpInstLockPermission === "true"
      );

      localStorage.bpDefReadPermission =
        permissions.includes("ROLE_BP_DEF_READ");
      this.$store.commit(
        "setBpDefReadPermission",
        localStorage.bpDefReadPermission === "true"
      );

      localStorage.bpDefCreatePermission = permissions.includes(
        "ROLE_BP_DEF_CREATION"
      );
      this.$store.commit(
        "setBpDefCreatePermission",
        localStorage.bpDefCreatePermission === "true"
      );

      localStorage.bpDefDeletePermission = permissions.includes(
        "ROLE_BP_DEF_DELETION"
      );
      this.$store.commit(
        "setBpDefDeletePermission",
        localStorage.bpDefDeletePermission === "true"
      );

      localStorage.bpDefModifyPermission =
        permissions.includes("ROLE_BP_DEF_MODIFY");
      this.$store.commit(
        "setBpDefModifyPermission",
        localStorage.bpDefModifyPermission === "true"
      );

      localStorage.processTaskReadPermission = permissions.includes(
        "ROLE_PROCESS_TASK_READ"
      );
      this.$store.commit(
        "setProcessTaskReadPermission",
        localStorage.processTaskReadPermission === "true"
      );

      localStorage.processTaskCreatePermission = permissions.includes(
        "ROLE_PROCESS_TASK_CREATION"
      );
      this.$store.commit(
        "setProcessTaskCreatePermission",
        localStorage.processTaskCreatePermission === "true"
      );

      localStorage.processTaskDeletePermission = permissions.includes(
        "ROLE_PROCESS_TASK_DELETION"
      );
      this.$store.commit(
        "setProcessTaskDeletePermission",
        localStorage.processTaskDeletePermission === "true"
      );

      localStorage.processTaskModifyPermission = permissions.includes(
        "ROLE_PROCESS_TASK_MODIFY"
      );
      this.$store.commit(
        "setProcessTaskModifyPermission",
        localStorage.processTaskModifyPermission === "true"
      );

      localStorage.securityPermission = permissions.includes("ROLE_SECURITY");
      this.$store.commit(
        "setSecurityPermission",
        localStorage.securityPermission === "true"
      );

      localStorage.importOlpPermission =
        permissions.includes("ROLE_IMPORT_OLP");
      this.$store.commit(
        "setImportOlpPermission",
        localStorage.importOlpPermission === "true"
      );

      localStorage.infoPermission = permissions.includes("ROLE_INFO");
      this.$store.commit(
        "setInfoPermission",
        localStorage.infoPermission === "true"
      );

      localStorage.fileEnvReadPermission =
        permissions.includes("ROLE_FILE_ENV_READ");
      this.$store.commit(
        "setFileEnvReadPermission",
        localStorage.fileEnvReadPermission === "true"
      );

      localStorage.fileEnvCreatePermission = permissions.includes(
        "ROLE_FILE_ENV_CREATION"
      );
      this.$store.commit(
        "setFileEnvCreatePermission",
        localStorage.fileEnvCreatePermission === "true"
      );

      localStorage.fileEnvDeletePermission = permissions.includes(
        "ROLE_FILE_ENV_DELETION"
      );
      this.$store.commit(
        "setFileEnvDeletePermission",
        localStorage.fileEnvDeletePermission === "true"
      );

      localStorage.fileEnvModifyPermission = permissions.includes(
        "ROLE_FILE_ENV_MODIFY"
      );
      this.$store.commit(
        "setFileEnvModifyPermission",
        localStorage.fileEnvModifyPermission === "true"
      );

      localStorage.envPropsPermission = permissions.includes(
        "ROLE_ENVIRONMENT_PROPS"
      );
      this.$store.commit(
        "setEnvPropsPermission",
        localStorage.envPropsPermission === "true"
      );

      localStorage.releaseDeletePermission = permissions.includes(
        "ROLE_RELEASE_DELETION"
      );
      this.$store.commit(
        "setReleaseDeletePermission",
        localStorage.releaseDeletePermission === "true"
      );

      localStorage.olpExportPermission = permissions.includes(
        "ROLE_RUN_OLP_EXPORT"
      );
      this.$store.commit(
        "setOlpExportPermission",
        localStorage.olpExportPermission === "true"
      );

      localStorage.repoEntityCreatePermission = permissions.includes(
        "ROLE_REPO_ENTITY_CREATION"
      );
      this.$store.commit(
        "setRepoEntityCreatePermission",
        localStorage.repoEntityCreatePermission === "true"
      );

      localStorage.fileDeletePermission =
        permissions.includes("ROLE_FILE_DELETION");
      this.$store.commit(
        "setFileDeletePermission",
        localStorage.fileDeletePermission === "true"
      );

      localStorage.logPermission = permissions.includes("ROLE_LOG");
      this.$store.commit(
        "setLogPermission",
        localStorage.logPermission === "true"
      );

      localStorage.rememberMe = true;
      this.$store.commit("setLoggedIn", localStorage.rememberMe === "true");
    },
  },
};
</script>

<style scoped>
.right {
  background: #333 !important;
}
</style>
