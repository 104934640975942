<i18n>
{
  "en":
  {
    "local" :
    {
      "label": "Confirm pasword",
      "ruleRepeatPassword": "Please repeat the new password"
    }
  },
  "fr":
  {
    "local" :
    {
      "label": "Confirmation du mot de passe",
      "ruleRepeatPassword": "La confirmation doit être identique au nouveau mot de passe"
    }
  }
}
</i18n>
<template>
  <compliance-password
    :key="key"
    :disabled="disabled"
    :label="$t('local.label')"
    :required="!!password"
    :rules="localRules"
    :rulesDependOn="password"
    :value="value"
    autocomplete="new-password"
    counter
    @input="emitInput"
  />
</template>

<script>
import CompliancePassword from "@/components/CompliancePassword";

export default {
  name: "compliance-password-confirm",
  components: {
    CompliancePassword,
  },
  props: {
    // nouveau mot de passe que l'utilisateur doit confirmer (répéter)
    password: {
      type: String,
      required: true,
    },
    /**
     * Bind to a variable if the rules depends on the variable.
     * This will relaunch the rules when the variable depend on changed
     */
    rulesDependOn: {
      type: [Object, String, Number, Array],
    },
    // Valeur du champ
    // Peut être liée dans les deux sens avec v-model
    value: {
      type: String,
    },
  },
  data() {
    return {
      key: 0,
      localRules: [this.ruleRepeatPassword],
    };
  },
  computed: {
    disabled() {
      return this.password.length === 0;
    },
  },

  watch: {
    password() {
      if (this.password === "") {
        this.$emit("input", "");
      }
    },
  },

  methods: {
    emitInput(newValue) {
      this.$emit("input", newValue);
    },
    ruleRepeatPassword(v) {
      return v === this.password || this.$i18n.t("local.ruleRepeatPassword");
    },
  },
};
</script>
