"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function combineProperties(propertiesText, propertyForm) {
    var result = propertiesText !== null && propertiesText !== void 0 ? propertiesText : "";
    if (propertyForm) {
        Object.entries(propertyForm).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            result += "\n" + key + "=" + value;
        });
    }
    return result;
}
exports.default = combineProperties;
