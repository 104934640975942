<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    :optionPartenaire="optionPartenaire"
    persistent
    @input="$emit('input', $event)"
  >
    <v-card :id="id" class="pb-1">
      <!-- en-tête -->
      <v-card-title v-if="!noToolBar">
        <span v-if="title">{{ title }}</span>
        <v-btn depressed class="ml-auto" v-if="!noClose" icon @click="hide">
          <v-icon>pic-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- contenu -->
      <v-card-text
        class="effisoft-dialog--content"
        :class="{ 'pb-4': !$slots.actions }"
      >
        <slot>
          Use EffisoftDialog's default slot to display something here.
        </slot>
      </v-card-text>

      <!-- boutons -->
      <v-card-actions
        v-if="$slots.actions"
        class="effisoft-dialog--actions justify-end"
      >
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "compliance-dialog",
  props: {
    id: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: "700px",
    },
    noClose: {
      type: Boolean,
      default: false,
    },
    noToolBar: {
      type: Boolean,
      default: false,
    },
    // Titre de la boîte de dialogue
    title: {
      type: String,
      required: false,
    },
    // Indique si la boîte de dialogue doit être affichée
    // À lier dans les deux sens avec v-model.
    value: {
      type: Boolean,
      required: true,
    },
    // param option liste
    optionPartenaire: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Ferme la boîte de dialogue
    hide() {
      this.$emit("input", false);
    },
  },
};
</script>
