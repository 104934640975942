<template>
  <create-edit-user :isEdit="false" :cancelRoute="'Users'" />
</template>

<script>
import CreateEditUser from "@/components/security/CreateEditUser";

export default {
  name: "CreateUser",
  components: {
    CreateEditUser,
  },
};
</script>
