<template>
  <create-edit-file-env
    isEdit
    :fileEnvId="+$route.params.fileEnvId"
    :cancelRoute="'File env'"
  />
</template>

<script>
import CreateEditFileEnv from "@/components/CreateEditFileEnv";

export default {
  name: "CreateFileEnv",
  components: {
    CreateEditFileEnv,
  },
};
</script>
