<template>
  <div id="home">
    <template>
      <navigation-drawer />
      <v-main class="main-page-content">
        <v-container fluid>
          <div id="module" class="the-content-component">
            <!-- Adding the key is necessary to make sure that the page is reloaded when we redirect to a different URL for the same view, e.g. from bp-instance/view/24 to bp-instance/view/10  -->
            <router-view :key="$route.fullPath" />
          </div>
        </v-container>
      </v-main>
    </template>
  </div>
</template>

<script>
import NavigationDrawer from "@/components/layout/NavigationDrawer";
export default {
  name: "ApplicationCore",
  components: {
    NavigationDrawer,
  },
};
</script>
