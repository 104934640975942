<template>
  <ooliba-basic-table
    :headers="headers"
    :items="items"
    :loading="busy"
    no-pagination
    @row-clicked="onRowClick"
    ><template #[`item.readPermission`]="{ item }">
      <v-icon small class="pr-4" v-if="item.readPermission" color="green">
        pic-check
      </v-icon>
      <div v-else></div> </template
    ><template #[`item.writePermission`]="{ item }">
      <v-icon small class="pr-4" v-if="item.writePermission" color="green">
        pic-check
      </v-icon>
      <div v-else></div>
    </template>
  </ooliba-basic-table>
</template>

<script>
import OolibaBasicTable from "@/components/OolibaBasicTable";

export default {
  name: "VaultPermissionsOolibaBasicTable",
  components: { OolibaBasicTable },
  props: {
    vaultPermissions: Array,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.administration.vault"), value: "vault" },
        {
          text: this.$t("global.administration.read-permission"),
          value: "readPermission",
        },
        {
          text: this.$t("global.administration.write-permission"),
          value: "writePermission",
        },
      ],
      items: [],

      busy: true,
      isError: false,
      errorMessage: "",
    };
  },
  methods: {
    onRowClick(record) {
      const vaultId = record.id;

      this.$router.push({ name: "Vault", params: { vaultId: vaultId } });
    },
  },
  async created() {
    this.vaultPermissions.forEach((value) => {
      const item = {};

      item["id"] = value.id;
      item[this.headers[0].value] = value.name;
      item[this.headers[1].value] = value.readPermission;
      item[this.headers[2].value] = value.writePermission;

      this.items.push(item);
    });
    this.busy = false;
  },
};
</script>
