<template>
  <v-card>
    <v-overlay absolute :value="uploading">
      <v-chip color="blue" dark>{{ $t("global.msg.uploading") }}</v-chip>
    </v-overlay>
    <v-card-title>{{ $t("global.concepts.warnings") }}:</v-card-title>
    <v-card-text>
      <v-alert
        v-for="(warning, index) in warnings"
        :key="'w' + index"
        type="warning"
        >{{ warning }}</v-alert
      >
    </v-card-text>
    <v-card-title>{{ $t("global.action.want-continue") }}</v-card-title>
    <v-card-actions>
      <v-btn @click="onCancel">{{ $t("global.action.no") }}</v-btn>
      <v-btn color="primary" @click="onConfirm">{{
        $t("global.action.yes")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "FileUploadWarnings",

  props: {
    warnings: Array,
    uploading: Boolean,
  },

  methods: {
    onConfirm() {
      this.$emit("confirmUpload");
    },

    onCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
