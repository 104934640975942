<template>
  <create-run-form></create-run-form>
</template>

<script>
import CreateRunForm from "@/components/run/CreateRunForm.vue";

export default {
  name: "CreateRun",
  components: {
    CreateRunForm,
  },
};
</script>
