<template>
  <v-card>
    <v-card-text>
      <bp-definition-vertex-builder
        v-if="initialized"
        :initVertices="graph.vertices"
        :tasks="tasks"
        @change="onVertexChange"
      ></bp-definition-vertex-builder>
      <bp-definition-edge-builder
        class="mt-4"
        v-if="initialized"
        :initEdges="graph.edges"
        :vertices="formattedVertices"
        @change="onEdgeChange"
      ></bp-definition-edge-builder>
    </v-card-text>
  </v-card>
</template>

<script>
import BpDefinitionVertexBuilder from "./BpDefinitionVertexBuilder.vue";
import BpDefinitionEdgeBuilder from "./BpDefinitionEdgeBuilder.vue";
import { get } from "@/model/api";

export default {
  name: "BpDefinitionBuilder",
  components: {
    BpDefinitionVertexBuilder,
    BpDefinitionEdgeBuilder,
  },
  props: {
    initJsonGraph: {},
  },
  data() {
    return {
      graph: {
        vertices: [],
        edges: [],
      },
      formattedVertices: [],
      initialized: false,
      tasks: [],
    };
  },
  watch: {
    initJsonGraph: function () {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      this.initialized = false;

      this.tasks = await this.getTasks();

      if (this.initJsonGraph) {
        this.graph = this.generateGraphFromJson(this.initJsonGraph, this.tasks);
        this.formattedVertices = this.formatVertices(this.graph.vertices);
      }
      this.initialized = true;
    },
    generateGraphFromJson(jsonGraph, tasks) {
      if (!jsonGraph) {
        return;
      }
      const parsedJson = JSON.parse(jsonGraph);
      const graph = {};
      graph.vertices = [];

      parsedJson?.vertices?.forEach((vertex) => {
        vertex.name = tasks.find(
          (task) => task.master.name === vertex.name
        )?.name;
        graph.vertices.push(vertex);
      });
      graph.edges = [];

      parsedJson?.edges?.forEach((edge) => {
        const toVertex = graph.vertices.find(
          (vertex) => vertex.id === parseInt(edge.to)
        );
        const fromVertex = graph.vertices.find(
          (vertex) => vertex.id === parseInt(edge.from)
        );
        const newEdge = {};

        newEdge.id = edge.id;
        newEdge.to = this.formatVertex(toVertex);
        newEdge.from = this.formatVertex(fromVertex);
        newEdge.blockOnWarning = edge.blockOnWarning;

        graph.edges.push(newEdge);
      });
      return graph;
    },
    generateJsonFromGraph(graph, tasks) {
      const convertedGraph = {};

      convertedGraph.vertices = [];

      graph.vertices.forEach((vertex) => {
        const newVertex = {};

        newVertex.id = vertex.id;
        newVertex.name = tasks.find(
          (task) => task.name === vertex.name
        )?.master?.name;
        newVertex.properties = vertex.properties;

        convertedGraph.vertices.push(newVertex);
      });
      convertedGraph.edges = [];

      graph.edges.forEach((edge) => {
        const newEdge = {};

        newEdge.id = edge.id;
        newEdge.from = this.getIdFromFormattedVertex(edge.from);
        newEdge.to = this.getIdFromFormattedVertex(edge.to);
        newEdge.blockOnWarning = edge.blockOnWarning;

        convertedGraph.edges.push(newEdge);
      });
      return JSON.stringify(convertedGraph);
    },
    formatVertices(vertices) {
      return vertices.map((vertex) => this.formatVertex(vertex));
    },
    formatVertex(vertex) {
      return vertex.id + " (" + vertex.name + ")";
    },
    getIdFromFormattedVertex(vertex) {
      if (!vertex) {
        return;
      }
      let split = vertex.split(" ");

      if (split.length < 2) {
        throw new Error(`Invalid formatted vertex: '${vertex}'`);
      }
      return split[0];
    },
    onVertexChange(vertices) {
      this.graph.vertices = vertices;
      this.formattedVertices = this.formatVertices(vertices);
      this.emitAsJson(this.graph);
    },
    emitAsJson(graph) {
      const jsonGraph = this.generateJsonFromGraph(graph, this.tasks);

      this.$emit("change", jsonGraph);
    },
    onEdgeChange(edges) {
      this.graph.edges = edges;
      this.emitAsJson(this.graph);
    },
    async getTasks() {
      return await get("/flow-task-config").catch((error) =>
        this.$store.commit("showError", error)
      );
    },
  },
  created() {
    this.initialize();
  },
};
</script>
