<template>
  <v-app>
    <v-snackbar
      v-model="$store.state.snackbar.show"
      bottom
      content-class="py-0 pr-0"
      min-width="fit-content"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
    >
      <span class="body-1">{{ $store.state.snackbar.text }}</span>
      <v-btn
        class="ml-1"
        text
        small
        elevation="0"
        @click.native="$store.state.snackbar.show = false"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
    <application-core v-if="isLoggedIn" />
    <user-login v-else />
  </v-app>
</template>

<script>
import ApplicationCore from "@/components/ApplicationCore";
import UserLogin from "@/components/UserLogin.vue";
export default {
  name: "App",
  components: {
    ApplicationCore,
    UserLogin,
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  created() {
    // for (const sheet of document.styleSheets) {
    //   for (const rule of sheet.cssRules) {
    //     if (rule.selectorText?.includes("::-webkit-scrollbar")) {
    //       console.log("Deleting rule (1): ", rule.selectorText);
    //       sheet.deleteRule(rule);
    //     }
    //   }
    // }

    // Remove the custom scrollbar styles applied by the Prima UI-kit
    // Taken from https://stackoverflow.com/a/66069228
    let styles = document.getElementsByTagName("style");

    for (let i = 0; i < styles?.length; i++) {
      let style = styles[i];
      let rules = style.sheet?.cssRules;

      for (let r = 0; r < rules?.length; r++) {
        let rule = rules[r];

        if (rule.selectorText?.includes("::-webkit-scrollbar")) {
          // console.log("Deleting rule (2): ", rule.selectorText);
          style.sheet.deleteRule(r);
        }
      }
    }
  },
};
</script>

<style>
.v-btn:focus::before {
  opacity: 0 !important;
}
</style>
