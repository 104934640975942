<template>
  <v-switch
    :class="classComputed"
    :dark="dark"
    :dense="dense"
    :hide-details="hideDetails"
    :value="value"
    @change="$emit('input', $event)"
  >
    <template #label>
      <span style="color: black">{{ label }}</span>
    </template>
  </v-switch>
</template>

<script>
export default {
  props: {
    cl: String,
    dark: Boolean,
    dense: Boolean,
    hideDetails: Boolean,
    label: String,
    reversed: Boolean,
    value: Boolean,
  },

  computed: {
    classComputed() {
      let classComputed = "";
      if (this.cl) {
        classComputed += ` ${this.cl}`;
      }

      if (this.reversed) {
        classComputed += ` v-input--reverse`;
      }

      return classComputed;
    },
  },
};
</script>
