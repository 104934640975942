<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="submit" class="mx-auto" v-model="valid">
          <v-row
            ><v-col cols="6">
              <v-text-field
                v-model="name"
                :label="$t('global.concepts.name') + '*'"
                :rules="[notEmptyRule, noTagsRule]"
                outlined
                persistent-placeholder
              >
              </v-text-field> </v-col
          ></v-row>

          <v-row
            ><v-col cols="6">
              <v-autocomplete
                :items="users"
                :label="$t('global.administration.owner') + '*'"
                :rules="[notEmptyRule]"
                v-model="owner"
                outlined
                persistent-placeholder
              ></v-autocomplete></v-col
          ></v-row>

          <form-buttons
            :canSubmit="valid"
            :confirmText="$t('global.action.save')"
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import FormButtons from "@/components/FormButtons";
import { post, get } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "CreateVault",
  components: {
    ModuleTemplate,
    FormButtons,
  },
  data() {
    return {
      name: "",
      owner: "",
      users: [],
      valid: false,
      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
    };
  },
  computed: {
    formTitle() {
      return this.$t("global.header.create", [
        this.$t("global.administration.vault").toLowerCase(),
      ]);
    },
  },
  methods: {
    onCancel() {
      this.$router.push({ name: "Vaults" });
    },

    async getUsers() {
      return await get("/user/usernames").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    async submit() {
      const data = {
        name: this.name,
        owner: this.owner,
      };
      await post("/vault/save", data)
        .then(() => {
          this.$router.push({ name: "Vaults" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    createUniqueConstraintMsg(e) {
      if (e.message === "unique.constraint" && e.arguments) {
        if (e.arguments[0] === "name") {
          return this.$t("global.msg.already-exists", [
            this.$t("global.administration.vault"),
            this.$t("global.concepts.name"),
            e.arguments[1],
          ]);
        }
      }
      return e;
    },
  },
  async created() {
    this.users = await this.getUsers();
  },
};
</script>
