<template>
  <v-card
    class="basic-card-horizontal"
    :style="`min-width:${minWidth}px`"
    :min-width="minWidth"
    :max-width="maxWidth"
    rounded
    shaped
  >
    <v-row v-for="header in headers" :key="header.text" dense>
      <v-col class="basic-card-horizontal-left py-0 pl-2" cols="4">
        <div>
          {{ header.text }}
        </div>
      </v-col>

      <v-col class="py-0 px-1" cols="8">
        <div :class="`${header.class} pr-2`" @click="onClick(header.value)">
          <slot :name="`item.${header.value}`" :item="item">
            {{ item[header.value] }}
          </slot>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    /**
     * A array of header with :
     * text: label
     * value: property name to find the value (from item)
     * class: class to apply on the value
     */
    headers: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
    },
    /**
     * Set min width in case of overflow
     */
    minWidth: {
      type: [String, Number],
      default: 370,
    },

    maxWidth: {
      type: [String, Number],
    },
  },
  methods: {
    onClick(header) {
      this.$emit("click", header);
    },
  },
};
</script>
