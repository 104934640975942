<template>
  <v-tabs
    v-if="run"
    class="ooliba-tab"
    active-class="ooliba-tab-active"
    v-model="tab"
  >
    <v-tab>{{ $t("global.run.input-files") }}</v-tab>
    <v-tab-item>
      <run-input-files v-if="run.id" :run="run" />
    </v-tab-item>

    <v-tab v-show="isSuccess">{{ $t("global.run.output-files") }}</v-tab>
    <v-tab-item>
      <run-output-files :run="run" />
    </v-tab-item>

    <!-- Reports tab -->
    <v-tab v-for="runReport in runReports" :key="runReport.id">{{
      runReport.title
    }}</v-tab>
    <v-tab-item v-for="runReport in runReports" :key="runReport.id">
      <run-report :runReport="runReport"></run-report>
    </v-tab-item>

    <v-tab v-show="warnings?.length > 0">{{ warningTabName }} </v-tab>
    <v-tab-item>
      <v-alert
        style="background-color: #fff4e7; border: 1px solid #efebe7"
        class="mb-0 py-5"
        v-for="(warning, index) in warnings"
        :key="'w' + index"
        icon="pic-warning"
      >
        {{ warning }}
      </v-alert>
    </v-tab-item>

    <v-tab v-show="errors?.length > 0"
      >{{ $t("global.concepts.errors") }}
    </v-tab>
    <v-tab-item>
      <v-alert
        style="background-color: #f9b5b5; border: 1px solid #efebe7"
        class="mb-0 py-5"
        v-for="(errors, index) in errors"
        :key="'e' + index"
        icon="pic-close"
      >
        {{ errors }}
      </v-alert>
    </v-tab-item>

    <v-tab v-show="run.memoryUsage && $store.state.infoPermission"
      >{{ $t("global.concepts.analysis") }}
    </v-tab>
    <v-tab-item>
      <run-analysis :data="run.memoryUsage"></run-analysis>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { get } from "@/model/api";

import RunInputFiles from "@/components/run/RunInputFiles";
import RunOutputFiles from "@/components/run/RunOutputFiles";
import RunReport from "@/components/run/RunReport";
import RunAnalysis from "@/components/run/RunAnalysis";

export default {
  name: "RunDetails",
  components: {
    RunInputFiles,
    RunOutputFiles,
    RunReport,
    RunAnalysis,
  },

  props: {
    run: {},
  },

  data() {
    return {
      runReports: [],
      errors: [],
      warnings: [],
      tab: undefined,
    };
  },

  computed: {
    isSuccess() {
      return this.run.status === "SUCCESS";
    },

    warningTabName() {
      return (
        this.$t("global.concepts.warnings") + " (" + this.warnings?.length + ")"
      );
    },
  },

  watch: {
    async run(newValue, oldValue) {
      if (
        oldValue.status !== newValue.status &&
        newValue.status === "SUCCESS"
      ) {
        this.runReports = await this.getRunReports();
        this.tab = 1;
      }
      if (oldValue.warnings !== newValue.warnings) {
        if (newValue.warnings) {
          this.warnings = newValue.warnings;
        }
      }
      if (oldValue.errors !== newValue.errors) {
        if (newValue.errors.length > 0) {
          this.errors = newValue.errors;
          this.tab = 3;
        }
      }
    },
  },

  methods: {
    async getRunReports() {
      const path = "/simulationRun/" + this.run.infoId + "/run-report";

      return await get(path).catch((err) =>
        this.$store.commit("showError", err)
      );
    },
  },
};
</script>
