<template>
  <v-menu offset-y :class="{ disabled: disabled }">
    <template #activator="{ on }">
      <!-- bouton d'activation de la boîte dialogue -->
      <slot name="activator" :on="on">
        <v-btn depressed v-on="on">ACTIVATOR TO REPLACE</v-btn>
      </slot>
    </template>

    <!-- boîte de dialogue -->
    <v-card>
      <v-card-text class="body-1 font-weight-medium">
        {{ text }}
      </v-card-text>
      <v-card-actions class="effisoft-dialog--actions justify-end">
        <v-spacer></v-spacer>
        <!-- bouton "non" -->
        <v-btn color="secondary" depressed>
          <v-icon left>pic-close</v-icon>
          {{ $t("global.action.no") }}
        </v-btn>
        <!-- bouton "oui" -->
        <v-btn id="btn-yes" color="primary" depressed @click="onYes">
          <v-icon left>pic-check</v-icon>
          {{ $t("global.action.yes") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "compliance-dialog-confirm",
  props: {
    disabled: {
      type: Boolean,
    },
    /**
     * Message de confirmation dans la langue de l'utilisateur
     */
    text: {
      type: [String, Object],
      required: true,
    },
  },
  methods: {
    onYes() {
      this.$emit("yes");
    },
  },
};
</script>
