<template>
  <v-navigation-drawer
    :expand-on-hover="expandOnHover"
    :mini-variant.sync="mini"
    :width="navigationWidth"
    app
    clipped-top
    fixed
    floating
    mini-variant-width="50"
    permanent
    ref="drawer"
  >
    <v-list-item>
      <v-list-item-content>
        <!-- change logo with theses classes : dct, dct-compliance, dct-reinsurance -->
        <v-list-item-title class="dct-compliance">
          <span class="header-icon" />
          <span class="header-tagline" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-treeview
      :items="computedNavItems"
      :active.sync="active"
      activatable
      active-class="v-treeview-node__active"
      expand-icon=""
      hoverable
      item-key="target"
      item-text="text"
      open-on-click
      transition
    >
      <template #prepend="{ item, active, open }">
        <template v-if="item.iconOpen || item.iconClose">
          <v-tooltip v-if="mini" class="menu-tooltip" right>
            <template #activator="{ on, attrs }">
              <v-icon v-if="!!item.iconOpen" v-bind="attrs" v-on="on">
                {{ active || open ? item.iconOpen : item.iconClose }}
              </v-icon>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
          <template v-else>
            <v-icon v-if="!!item.iconOpen">
              {{ active || open ? item.iconOpen : item.iconClose }}
            </v-icon>
          </template>
        </template>

        <template v-if="item.children && !(item.iconOpen || item.iconClose)">
          <v-icon>
            {{
              active || open
                ? "pic-collapse-circle-fill"
                : "pic-add-circle-fill"
            }}
          </v-icon>
        </template>
      </template>

      <template #append="{ item }">
        <v-icon v-if="!!item.children"> pic-arrow-right </v-icon>
      </template>
    </v-treeview>

    <div class="footer-nav">
      <div class="footer">
        <v-icon v-if="mini" @click.stop="showMiniVariant">
          pic-arrow-right hasRotate
        </v-icon>
        <v-icon v-else @click.stop="showMiniVariant"> pic-arrow-right </v-icon>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",

  data() {
    return {
      mini: false,
      expandOnHover: false,
      isLoadCompanies: false,
      active: [],
      itemsData: [
        {
          text: this.$t("global.menu.business-processes.header"),
          target: "dummyBps",
          iconOpen: "pic-module-fill",
          iconClose: "pic-module",
          allowed:
            this.$store.state.bpInstReadPermission ||
            this.$store.state.bpDefReadPermission ||
            this.$store.state.processTaskReadPermission,
          children: [
            {
              text: this.$t("global.menu.business-processes.instances"),
              target: "BP instances",
              allowed: this.$store.state.bpInstReadPermission,
            },
            {
              text: this.$t("global.menu.business-processes.definitions"),
              target: "BP definitions",
              allowed: this.$store.state.bpDefReadPermission,
            },
            {
              text: this.$t("global.menu.business-processes.process-tasks"),
              target: "Process tasks",
              allowed: this.$store.state.processTaskReadPermission,
            },
          ],
        },

        {
          text: this.$t("global.menu.runs"),
          target: "Runs",
          iconOpen: "pic-run-circle-fill",
          iconClose: "pic-run-circle",
          allowed: this.$store.state.runReadPermission,
        },

        {
          text: this.$t("global.menu.modules"),
          target: "Modules",
          iconOpen: "pic-database-edit-fill",
          iconClose: "pic-database-edit",
          allowed: true,
        },

        {
          text: this.$t("global.menu.files"),
          target: "Files",
          iconOpen: "pic-contract-fill",
          iconClose: "pic-contract",
          allowed: true,
        },

        {
          text: this.$t("global.concepts.security"),
          target: "dummySecurity",
          iconOpen: "pic-key-fill",
          iconClose: "pic-key",
          allowed: this.$store.state.securityPermission,
          children: [
            {
              text: this.$t("global.menu.administration.users"),
              target: "Users",
              allowed: this.$store.state.securityPermission,
            },

            {
              text: this.$t("global.menu.administration.roles"),
              target: "Roles",
              allowed: this.$store.state.securityPermission,
            },

            {
              text: this.$t("global.menu.administration.vaults"),
              target: "Vaults",
              allowed: this.$store.state.securityPermission,
            },
          ],
        },

        {
          text: this.$t("global.concepts.system"),
          target: "dummySystem",
          iconOpen: "pic-settings-fill",
          iconClose: "pic-settings",
          allowed:
            this.$store.state.fileEnvReadPermission ||
            this.$store.state.envPropsPermission ||
            this.$store.state.logPermission ||
            this.$store.state.infoPermission,
          children: [
            {
              text: this.$t("global.menu.file-environments"),
              target: "File envs",
              allowed: this.$store.state.fileEnvReadPermission,
            },

            {
              text: this.$t("global.menu.environment-properties"),
              target: "Properties",
              allowed: this.$store.state.envPropsPermission,
            },

            {
              text: this.$t("global.concepts.log"),
              target: "Log",
              allowed: this.$store.state.logPermission,
            },

            {
              text: this.$t("global.info.info"),
              target: "Info",
              allowed: this.$store.state.infoPermission,
            },
          ],
        },
      ],
      navigationWidth: 220,
    };
  },
  computed: {
    computedNavItems() {
      return this.filterNavTree(this.itemsData);
    },
  },

  watch: {
    active(val) {
      if (val.length > 0 && this.$route.name !== val[0]) {
        this.$router.push({ name: val[0] });
      }
    },

    $route(to) {
      if (this.active.length === 0 || this.active[0] !== to.name) {
        this.active = [to.name];
      }
    },
  },

  methods: {
    filterNavTree(nav) {
      if (Array.isArray(nav)) {
        return nav
          .map((item) => this.filterNavTree(item))
          .filter((item) => item);
      }

      if (nav.children) {
        let newNav = Object.assign({}, nav);
        newNav.children = this.filterNavTree(nav.children);
        if (newNav.children.length === 0) {
          return null;
        }
        return newNav;
      }

      if (!nav.allowed) {
        return null;
      }

      return nav;
    },

    showError(ex) {
      this.$store.commit("showError", ex.message);
    },

    showMiniVariant() {
      this.mini = !this.mini;
    },
  },

  mounted() {
    this.active = [this.$route.name];
  },
};
</script>
