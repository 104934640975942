<template>
  <module-template id="scrolltainer" title canList @list="listModules">
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.modules.module") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ moduleName }}</div>
      </v-row>
    </template>
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      sort-by="version"
      sort-desc
      numberItemShow="100"
      no-pagination
    >
      <template #[`item.action`]="{ item }">
        <v-icon small color="error" @click="deleteConfirm(item)">
          pic-delete
        </v-icon>
      </template>
      <template #[`item.uploadedOn`]="{ item }">
        {{ formatDate(item.uploadedOn) }}
      </template>
    </ooliba-basic-table>
    <v-dialog v-model="showDeleteConfirm" width="500">
      <confirm-alert
        @cancel="cancelDelete"
        @confirm="deleteVersion"
      ></confirm-alert>
    </v-dialog>
  </module-template>
</template>

<script>
import OolibaBasicTable from "@/components/OolibaBasicTable";
import ConfirmAlert from "@/components/ConfirmAlert";
import { get, remove } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { formatDate, versionCompare } from "@/model/util";

export default {
  name: "ModuleVersions",
  components: {
    OolibaBasicTable,
    ConfirmAlert,
    ModuleTemplate,
  },
  data() {
    return {
      moduleName: "",
      moduleVersions: [],
      headers: [
        { text: this.$t("global.modules.module"), value: "module" },
        {
          text: this.$t("global.concepts.version"),
          value: "version",
          sort: (a, b) => this.versionCompare(a, b),
        },
        { text: this.$t("global.concepts.uploaded-on"), value: "uploadedOn" },
        {
          text: this.$t("global.concepts.actions"),
          value: "action",
          sortable: false,
        },
      ],
      items: [],
      showDeleteConfirm: false,
      busy: true,
    };
  },

  methods: {
    formatDate,
    versionCompare,
    listModules() {
      this.$router.push({ name: "Modules" });
    },
    async getModuleVersions(module) {
      return await get("/release/versions/" + module).catch((error) => {
        this.$store.commit("showError", error);
      });
    },
    async deleteVersion() {
      await remove("/release/" + this.deletingVersion.id)
        .then(() => {
          this.loadTableData();
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
      this.showDeleteConfirm = false;
    },
    deleteConfirm(row) {
      this.deletingVersion = row;
      this.showDeleteConfirm = true;
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
    },
    async loadTableData() {
      this.busy = true;
      this.items = [];
      this.moduleName = this.$route.params.moduleName;
      this.versions = await this.getModuleVersions(this.moduleName);

      if (this.versions) {
        this.versions.forEach((value) => {
          const item = {};

          item["id"] = value.id;
          item[this.headers[0].value] = value.moduleName;
          item[this.headers[1].value] = value.versionString;
          item[this.headers[2].value] = value.dateCreated;

          this.items.push(item);
        });
      }
      this.busy = false;
    },
  },
  async created() {
    await this.loadTableData();
  },
};
</script>
