<template>
  <v-container>
    <h6 class="py-4">
      {{ $t("global.business-process.dependencies") }}
    </h6>

    <v-row>
      <v-col cols="1">
        <id-list
          :items="edges"
          v-model="selectedRow"
          @input="onEdgeSelect"
        ></id-list>
      </v-col>
      <v-col cols="11">
        <v-autocomplete
          class="mb-2"
          v-if="selectedEdge"
          :items="vertices"
          outlined
          clearable
          :label="$t('global.concepts.from')"
          :rules="[notEmptyRule]"
          v-model="selectedEdge.from"
          persistent-placeholder
          @change="onChange"
        ></v-autocomplete>
        <v-autocomplete
          v-if="selectedEdge"
          :items="vertices"
          outlined
          clearable
          :rules="[notEmptyRule]"
          :label="$t('global.concepts.to')"
          v-model="selectedEdge.to"
          persistent-placeholder
          @change="onChange"
        ></v-autocomplete>
        <v-checkbox
          v-if="selectedEdge"
          v-model="selectedEdge.blockOnWarning"
          :label="$t('global.business-process.block-warning')"
          dense
          color="primary"
          @change="onChange"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-btn depressed class="mt-2 button" fab rounded @click="onAdd">
      <v-icon>pic-add</v-icon>
    </v-btn>
    <v-btn
      depressed
      class="ml-10 mt-2 button"
      color="error"
      fab
      rounded
      v-if="canRemoveEdge()"
      @click="onRemove"
    >
      <v-icon>pic-delete</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import IdList from "../../IdList";
import { notEmpty } from "@/model/rules";

export default {
  name: "BpDefinitionEdgeBuilder",
  props: {
    initEdges: [],
    vertices: [],
  },
  components: {
    IdList,
  },
  data() {
    return {
      edges: [],
      selectedRow: -1,
      selectedEdge: undefined,
      notEmptyRule: notEmpty(this),
    };
  },
  watch: {
    initEdges: function () {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (!this.initEdges || this.initEdges.length < 1) {
        return;
      }
      this.edges = this.initEdges;
      this.selectedRow = this.edges.length - 1;
      this.selectedEdge = this.edges[this.selectedRow];
    },
    onAdd() {
      let numEdges = this.edges.length;
      let id = numEdges < 1 ? 1 : this.edges[numEdges - 1].id + 1;
      this.edges.push(this.createEdge(id));
      this.onChange();
      this.selectedRow = this.edges.length - 1;
      this.selectedEdge = this.edges[this.selectedRow];
    },
    createEdge(id) {
      return {
        id: id,
        from: "",
        to: "",
        blockOnWarning: false,
      };
    },
    canRemoveEdge() {
      return this.edges.length > 0;
    },
    onEdgeSelect() {
      if (this.selectedRow === -1) {
        return;
      }
      if (this.selectedRow > this.edges.length) {
        throw new Error(`Invalid row selected: '${this.selectedRow}'`);
      }
      this.selectedEdge = this.edges[this.selectedRow];
    },
    onRemove() {
      if (this.canRemoveEdge()) {
        this.edges.splice(this.selectedRow, 1);
        this.onChange();
        this.selectedRow = this.selectedRow - 1;
        this.selectedEdge = this.edges[this.selectedRow];
      }
    },
    onChange() {
      this.$emit("change", this.edges);
    },
  },
  created() {
    this.initialize();
  },
};
</script>
